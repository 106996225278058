
.brand-list {
	// border-top: 1px solid darken($white,10%)
	// border-left: 1px solid darken($white,10%)
	.row {
 }		// margin: 0px 0px
	.col-6, .col-12 {
		margin-bottom: 20px;
 }		// padding: 0px 0px
	.col-custom {
		@include media-breakpoint-up(xl) {
 } }			// +flex(0 0 20%)
	.brand-col {
		display: block;
		figure {
			.brand-img {
				display: block;
				@include pos(rel,null);
				overflow: hidden;
				&:before, &:after {
					content: '';
					width: 100%;
					height: 100%;
					background: color-rgba($black,0.3);
					@include pos(abs, 0 null null 0);
					z-index: 1;
					@include transition(all,0.5s); }
				&:before {
					@include transform(translate(-100%,100%)); }
				&:after {
					@include transform(translate(100%,-100%)); }
				img {
					filter: brightness(50%);
					@include transition(all,0.5s); }
				.brand-logo {
					@include pos(abs, 0 null 0 0);
					right: 0;
					@include flexbox;
					flex-wrap: wrap;
					justify-content: center;
					align-items: center;
					align-content: center;
					opacity: 1;
					z-index: 5;
					@include transition(all,0.5s);
					img {
						max-width: 60%;
						max-height: 60%;
						filter: invert(100%) grayscale(100%) brightness(200%);
						@include transition(all,0.5s); } } }
			figcaption {
				@include transition(all,0.5s);
				@include pos(rel,null); }
			.brand-name {
				@include fs(18rem);
				font-weight: 600;
				color: $black;
				@include flexbox;
				justify-content: center;
				align-items: center;
				align-content: center;
				height: 60px;
				border: 1px solid darken($white,20%);
				border-top: none;
				@include pos(abs, null 0 0 0);
				@include transition(all,0.5s);
				visibility: hidden;
				opacity: 0;
				z-index: 20;
				background: $white;
				img {
					max-height: 60%;
					max-width: 60%; } }
			.brand-des {
				@include fs(14rem);
				@include pos(abs, 0 null 0 0);
				right: 0;
				@include flexbox;
				justify-content: center;
				align-items: center;
				align-content: center;
				color: $white;
				opacity: 0;
				visibility: hidden;
				z-index: 10; }
			&:hover {
				box-shadow: 0px 10px 20px -10px color-rgba($black,0.3);
				.brand-img {
					&:before, &:after {
						@include transform(translate(0,0)); }
					> img {
						@include transform(scale3d(1.1,1.1,1.1)); }
					.brand-logo {
						opacity: 0; } }
				figcaption {}
				.brand-name {
					@include transform(translateY(100%));
					opacity: 1;
					visibility: visible; }
				.brand-des {
					opacity: 1;
					visibility: visible; } } } } }

.brand-intro {
	border-top: 1px solid darken($white,10%);
	padding-top: 30px;
	.brand-logo {
		margin-bottom: 20px;
		img {
			max-width: 500px;
			max-height: 100px;
			@include media-breakpoint-down(lg) {
				max-width: 50%;
				max-height: 50%; } } }
	.content {
		max-height: 200px;
		overflow: hidden;
		margin-bottom: 15px;
		@include transition(all,0.5s);
		@include pos(rel,null);
		&:after {
			content: '';
			@include pos(abs, null 0 0 0);
			height: 50px;
			background: linear-gradient(color-rgba($white,0),color-rgba($white,1)); }
		&.open {
			max-height: 10000px;
			&:after {
				display: none; } } }
	.btn-wrap {
		text-align: center; }
	.btn-expand {
		display: inline-block;
		@include fs(13rem);
		border: 1px solid darken($white,10%);
		padding: 8px 30px;
		color: $main;
		text-transform: uppercase;
		&:after {
			content: '\f107';
			margin-left: 5px;
			display: inline-block; }
		&.active {
			&:after {
				@include transform(scaleY(-1)); } }
		&:hover {
			background: $black;
			color: $white;
			border-color: $black; } } }

.top-nav {
	margin-bottom: 30px;
	ul {
		text-align: center;
		background: darken($white,5%);
		// border-bottom: 1px solid $main
		li {
			display: inline-block;
			a {
				display: block;
				padding: 10px 20px;
				// font-weight: 500
				text-transform: uppercase;
				color: darken($white,40%);
				@include pos(rel,null);
				// &:after
				// 	content: ''
				// 	display: block
				// 	width: 0px
				// 	height: 3px
				// 	background: $main
				// 	+pos(abs, null null 0 50%)
				// 	+transform(translateX(-50%))
 }				// 	+transition(all,0.5s)
			&:hover {
				a {
					color: $main;
					&:after {
						width: 60px; } } }
			&.active {
				a {
					color: $white;
					background: $black;
					&:after {
						width: 60px; } } } } } }

#map {
	iframe {
		pointer-events: none;
		@include media-breakpoint-down(sm) {
			height: 300px; }
		&.active {
			pointer-events: all; } } }
.contact-map {
	// +bor-rad(5px)
	overflow: hidden;
	margin-bottom: 30px;
	border: 1px solid darken($white,10%); }
.contact-page {
	padding: 0px 0 40px;
	.contact-info {
		background: url(../img/contactbg.png) top left repeat;
		background: darken($white,3%);
		padding: 15px;
		@include media-breakpoint-down(sm) {
			margin-bottom: 30px; }
		.company {
			font-weight: 500;
			color: $black;
			@include fs(18rem);
			margin-bottom: 10px;
			line-height: 1.3;
			text-transform: uppercase;
			@include media-breakpoint-down(md) {
				@include fs(15rem); } }
		.title {
			font-weight: 700;
			@include fs(16rem);
			margin-bottom: 10px; }
		ul {
			margin-bottom: 30px;
			&:last-child {
				margin-bottom: 0; }
			li {
				@include pos(rel,null);
				padding-left: 25px;
				@include fs(14rem);
				line-height: 1.8;
				&:last-child {
					margin-bottom: 0; }
				.fa {
					color: $red;
					@include pos(abs, 4px null null 0);
					width: 20px;
					// +fs(18rem)
					text-align: center; }
				a {
					color: $main;
					&:hover {
						color: $red; } }
				.viewmap {
					color: $red;
					@include fs(14rem);
					&:hover {
						text-decoration: underline; }
					&:before {
						content: '\f278';
						font-family: 'fontawesome';
						margin-right: 5px; } } } } }
	.working-time {
		background: url(../img/contactbg.png) top left repeat;
		padding: 30px 35px;
		margin-bottom: 30px;
		@include media-breakpoint-down(md) {
			padding: 20px; }
		.title {
			@include fs(16rem);
			font-weight: 700;
			color: $main;
			margin-bottom: 10px;
			@include media-breakpoint-down(md) {
				@include fs(15rem); } }
		.time {
			@include pos(rel,null);
			padding-left: 30px;
			@include fs(18rem);
			margin-bottom: 5px;
			@include media-breakpoint-down(md) {
				@include fs(16rem); }
			.fa {
				color: $red;
				@include pos(abs, 2px null null 0);
				width: 20px;
				text-align: center;
				@include fs(24rem);
				@include media-breakpoint-down(md) {
					@include fs(20rem); } }
			span {
				display: block;
				@include fs(14rem);
				@include media-breakpoint-down(md) {
					@include fs(13rem); } } } } }

.contact-form {
	.module-title {
		margin-bottom: 10px;
		@include fs(14rem);
		line-height: 1.5; }
	.form-wrap {
		margin: 0px -10px; }
	.form-group {
		padding: 0px 10px;
		margin-bottom: 15px;
		float: left;
		width: 100%;
		&.col2 {
			width: 50%;
			float: left;
			@include media-breakpoint-down(md) {
				width: 100%; } }
		label {
			display: none; }
		label.fileinput {
			display: block;
			margin-bottom: 10px;
			// text-transform: uppercase
			@include fs(14rem);
			color: $black;
			font-style: italic;
			span {
				text-transform: none; } }
		input, textarea, select {
			width: 100%;
			border: 1px solid darken($white,10%);
			padding: 10px 15px;
 }			// +bor-rad(3px)
		select {
			padding: 9px 10px;
			color: darken($white,40%);
			option {} }
		input[type="file"] {
			padding: 5px;
			margin-bottom: 15px;
			width: 50%;
			@include media-breakpoint-down(md) {
				width: 100%; } }
		.des {
			@include fs(14rem);
			color: darken($white,60%);
			font-style: italic; } }
	.captcha {
		padding: 0 10px;
		margin-bottom: 15px;
		clear: both;
		width: 100%; }
	.btnwrap {
		clear: both;
		padding: 0px 10px;
		input {
			background: $gray;
			line-height: 36px;
			// +bor-rad(3px)
			@include fs(14rem);
			color: $white;
			text-transform: uppercase;
			padding: 0 25px;
			font-weight: 500;
			border: none;
			&:hover {
				background: $red; } } } }

.map-popup {
	padding: 10px;
	.modal-dialog {
		@include pos(rel,null); }
	.close {
		width: 30px;
		height: 30px;
		line-height: 24px;
		opacity: 1;
		@include bor-rad(50%);
		background: $red;
		color: $white;
		@include pos(abs,-15px -15px null null);
		span {
			display: block;
			height: 24px;
			line-height: 1;
			margin-top: -5px; } } }

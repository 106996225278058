.brand-group {
	@include media-breakpoint-down(md) {
		display: none; } }
// .brand-logo
// 	display: flex
// 	height: 150px
// 	justify-content: center
// 	align-items: center
// 	// border-top: 1px solid darken($white,10%)
// 	img
// 		max-width: 80%
// 		max-height: 80%
.btn-togglewrap {
	@include pos(fix, null 0 0 0);
	z-index: 50;
	@include flexbox;
	background: $white;
	border-top: 1px solid darken($white,15%);
	.btn-showcate, .btn-showfilter {
		text-align: center;
		line-height: 40px;
		padding: 0px;
		width: 100%;
		&.active {
			background: darken($white,5%); } }
	.btn-showcate {
		&:before {
			content: '\f00b';
			font-family: 'fontawesome';
			margin-right: 10px;
			line-height: 1;
			// +fs(18rem)
			display: inline-block;
			vertical-align: middle;
			@include media-breakpoint-down(xs) {
				// +fs(15rem)
				margin-right: 5px; } } }
	.btn-showfilter {
		border-left: 1px solid darken($white,15%);
		&:before {
			content: '\f013';
			font-family: 'fontawesome';
			margin-right: 10px;
			line-height: 1;
			// +fs(18rem)
			display: inline-block;
			vertical-align: middle;
			@include media-breakpoint-down(xs) {
				// +fs(15rem)
				margin-right: 5px; } } } }
.btn-closefilter {
	@include pos(abs, 30px 30px null null);
	@include fs(24rem);
	color: $main;
	@include transition(all,0.3s);
	line-height: 1;
	display: none;
	&:hover {
		color: $dred; }
	@include media-breakpoint-down(md) {
 }		// display: block
	@include media-breakpoint-down(sm) {
		top: 15px;
		right: 15px; }
	@include media-breakpoint-down(xs);
	&.btn-close {
		@include pos(static,unset);
		border: 1px solid lighten($red,40%);
		background: lighten($red,48%);
		@include fs(14rem);
		text-align: center;
		padding: 8px 0;
		@include bor-rad(3px);
		color: $red;
		@include media-breakpoint-down(md) {
			display: block; }
		&:hover {
			color: $dred;
			border-color: $dred;
			background: lighten($dred,45%); } } }
.filter-col {
 }	// background: darken($white,3%)
.filter-wrap {
	// background: darken($white,2%)
	// padding: 10px 15px
	margin-bottom: 20px;
	@include media-breakpoint-down(md) {
		margin: 0;

		@include pos(fix, 60px null 41px 0);
		z-index: 70;
		right: 0;
		background: $white;
		overflow: auto;
		padding: 30px 200px;
		@include transform(translateY(-130%));
		@include transition(all,0.5s);
		// opacity: 0
		visibility: hidden;
		&.open {
			@include transform(translateY(0%));
			// opacity: 1
			visibility: visible; } }
	@include media-breakpoint-down(sm) {
		padding: 30px 100px; }
	@include media-breakpoint-down(xs) {
		padding: 15px; }
	.filter-group {
		margin-bottom: 20px;
		&:last-child {
			margin-bottom: 20px; }
		&:before, &:after {
			content: '';
			display: table;
			width: 100%;
			clear: both; }
		.btn-reset {
			@include pos(abs, 0px 0px null null);
			width: 24px;
			display: none;
			background: $white;
			height: 24px;
			line-height: 24px;
			@include fs(10rem);
			z-index: 2;
			text-align: center;
			cursor: pointer;
			@include transition(all,0.3s);
			color: darken($white,40%);
			&:hover {
				color: $dred; } } }
	.filter-title {
		color: $main;
		@include pos(rel,null);
		margin-bottom: 10px;
		&:after {
			content: '';
			height: 2px;
			@include pos(abs, 50% 0 null 0);
			background: darken($white,10%); }
		span {
			display: inline-block;
			font-weight: 600;
			text-transform: uppercase;
			@include fs(13rem);
			@include pos(rel,null);
			z-index: 1;
			background: $white;
			padding-right: 10px; } }
	.filter-option {
		@include bor-rad(5px); }
	.filter-category {
		padding: 10px 15px;
		background: darken($white,3%);
		> ul {
			> li {
				@include pos(rel,null);
				> a {
					display: block;
					color: darken($white,40%);
					// font-weight: 700
					@include fs(14rem);
					@include pos(rel,null);
					padding: 2px 0;
					&:before {
						content: '\f105';
						margin-right: 10px; }
					// &:before
					// 	content: ''
					// 	display: block
					// 	width: 14px
					// 	height: 14px
					// 	border: 1px solid color-rgba($black,0.2)
					// 	+pos(abs, 5px null null 0)
					// &:after
					// 	content: '\f00c'
					// 	color: $red
					// 	display: block
					// 	width: 14px
					// 	height: 14px
					// 	+fs(14rem)
					// 	font-weight: normal
					// 	text-align: center
					// 	line-height: 10px
					// 	+pos(abs, 6px null null 0)
 }					// 	opacity: 0
				&:hover {
					> a {
						color: $black; } }
				&.active {
					> a {
						color: $black;
						font-weight: 500;
						// &:after
 }						// 	opacity: 1
					.sub {
						display: block; } }
				.btn-showsubfilter {
					@include pos(abs, 0px 0 null null);
					// width: 30px
					height: 30px;
					line-height: 30px;
					text-align: center;
					cursor: pointer; }
				.sub {
					padding: 5px 15px;
					background: darken($white,3%);
					@include bor-rad(5px);
					display: none;
					ul {
						li {
							padding: 2px 0;
							a {
								display: block;
								@include fs(13rem);
								@include pos(rel,null);
								color: darken($white,60%);
								padding-left: 25px;
								&:before {
									content: '';
									display: block;
									width: 14px;
									height: 14px;
									border: 1px solid darken($white,20%);
									@include pos(abs, 2px null null 0); }
								&:after {
									content: '\f00c';
									color: $dred;
									display: block;
									width: 14px;
									height: 14px;
									@include fs(12rem);
									text-align: center;
									line-height: 1;
									@include pos(abs, 3px null null 0);
									opacity: 0; } }
							&:hover {
								> a {
									color: $gray; } }
							&.active {
								> a {
									color: $gray;
									font-weight: 500;
									&:after {
										opacity: 1; } } } } } } } } }
	.filter-ajax {
		max-height: 200px;
		overflow: auto;
		&::-webkit-scrollbar-track {
			background-color: darken($white,10%); }
		&::-webkit-scrollbar {
			width: 5px;
			background-color: darken($white,10%); }
		&::-webkit-scrollbar-thumb {
			background-color: darken($white,40%); }
		> ul {
			> li {
				@include pos(rel,null);
				> a {
					display: block;
					color: darken($white,40%);
					@include fs(13rem);
					padding: 2px 0;
					@include pos(rel,null);
					padding-left: 25px;
					&:before {
						content: '';
						display: block;
						width: 14px;
						height: 14px;
						border: 1px solid color-rgba($black,0.2);
						@include pos(abs, 4px null null 0); }
					&:after {
						content: '\f00c';
						color: $red;
						display: block;
						width: 14px;
						height: 14px;
						@include fs(14rem);
						font-weight: normal;
						text-align: center;
						line-height: 10px;
						@include pos(abs, 5px null null 0);
						opacity: 0; }
					&:hover {
						color: $black; }
					&.active {
						font-weight: 500;
						color: $black;
						&:after {
							opacity: 1; } } } } }
		&.brand-category {
			border: 1px solid darken($white,10%);
			padding: 10px 15px;
			> ul {
				> li {} } } }

	.product-size {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		.size, li {
			margin-right: 15px;
			margin-bottom: 15px;
			a {
				display: block;
				font-weight: 600;
				color: darken($white,40%);
				@include fs(14rem);
				&:hover {
					color: $orange; }
				&.active {
					color: $orange;
					font-weight: 800; } } } }
	.color-picker {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		// margin: 0 -10px
		.color {
			width: 100%;
			// width: 50%
			// padding: 0px 10px
			a {
				@include flexbox;
				padding: 5px 0;
				.color-img {
					width: 14px;
					height: 14px;
					@include bor-rad(50px);
					@include pos(rel,null);
					border: 1px solid darken($white,10%); }
				.color-name {
					@include fs(13rem);
					line-height: 14px;
					margin-left: 10px;
					color: darken($white,40%); }
				&:hover {
					.color-name {
						color: $black; } }
				&.active {
					.color-img {
						border-color: $red; }
					.color-name {
						color: $black;
						font-weight: 500; } } } } }
	.price-select {
		select {
			padding: 7px 15px;
			width: 100%;
			@include fs(14rem);
			// +bor-rad(5px)
			border: 1px solid darken($white,20%); } }
	.filter-range {
		// width: 100%
		margin: 0px 10px;
		@include pos(rel,null);
		&:before, &:after {
			content: '';
			display: table;
			width: 100%;
			clear: both; }
		#slider-range {
			width: 100%;
			height: 4px;
			background: darken($white,10%);
			margin: 15px 0 15px;
			@include pos(rel,null);
			@include transition(all,0s); }
		.ui-widget-header {
			background: $orange;
			position: absolute;
			z-index: 1;
			display: block;
			top: 0px;
			height: 4px;
			@include transition(all,0s); }
		.ui-slider-handle {
			position: absolute;
			z-index: 2;
			cursor: default;
			touch-action: none;
			top: 2px;
			margin-left: -0.6em;
			left: 15%;
			width: 14px;
			height: 14px;
			@include bor-rad(50%);
			background: $white;
			border: 2px solid $orange;
			z-index: 1;
			cursor: pointer;
			@include transition(all,0s);
			@include transform(translateY(-50%));
			&:hover {
				background: $orange; } }
		.min, .max {
			width: 16px;
			height: 16px;
			background: darken($white,60%);
			box-shadow: 0px 0px 5px -1px rgba(0,0,0,.7);
			z-index: 1;
			cursor: pointer;
			@include transition(all,0.5s);
			@include transform(translateY(-50%));
			&:hover {
				background: $main; } }
		.min {
			@include pos(abs, 50% null null 0); }
		.max {
			@include pos(abs, 50% 0 null null); } }
	.price-range {
		// text-transform: uppercase
		@include fs(13rem);
		// font-weight: bold
		margin-left: -10px; }
	.min-input, .max-input {
		display: inline-block;
		margin: 0 5px;
		font-weight: bold;
		@include fs(14rem);
		color: $orange; } }

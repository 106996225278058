// CART STEP

.cartstep {
	background: darken($white,5%);
	margin: 40px 0 15px;
	overflow: hidden;
	@include bor-rad(5px);
	// border: 1px solid #BDBDBD
	@include media-breakpoint-down(lg) {
		margin: 15px 0; }
	.step {
		width: 25%;
		float: left;
		padding: 10px 25px;
		@include pos(rel,null);
		// border: 1px solid darken($white,10%)
		@include media-breakpoint-down(md) {
			padding: 10px; }
		@include media-breakpoint-down(xs) {
			padding: 5px; }
		&:first-child {
			@include bor-rad( 5px 0 0 5px); }
		&:last-child {
			@include bor-rad( 0 5px 5px 0); }
		&:before {
			content: '';
			display: block;
			width: 0;
			height: 0;
			border-top: 27px solid transparent;
			border-bottom: 27px solid transparent;
			border-right: 0 solid transparent;
			border-left: 16px solid darken($white,10%);
			@include pos(abs, -2px -1px null null);
			@include transform(translateX(15px));
			z-index: 10;
			@include media-breakpoint-down(md) {
				display: none; } }
		&:after {
			content: '';
			display: block;
			width: 0;
			height: 0;
			border-top: 25px solid transparent;
			border-bottom: 25px solid transparent;
			border-right: 0 solid transparent;
			border-left: 15px solid darken($white,5%);
			@include pos(abs, 0px 0px null null);
			@include transform(translateX(15px));
			z-index: 11;
			@include media-breakpoint-down(md) {
				display: none; } }
		&:last-child {
			&:before, &:after {
				display: none; } }
		.number {
			display: inline-block;
			vertical-align: middle;
			width: 30px;
			height: 30px;
			line-height: 30px;
			text-align: center;
			background: darken($white,15%);
			color: $main;
			@include bor-rad(50px);
			@include media-breakpoint-down(md) {
				display: block;
				margin: 0 auto 10px; }
			@include media-breakpoint-down(xs) {
				margin-bottom: 5px;
				width: 24px;
				height: 24px;
				line-height: 24px;
				@include fs(15rem); } }
		.name {
			display: inline-block;
			vertical-align: middle;
			padding-left: 10px;
			@include fs(13rem);
			@include media-breakpoint-down(md) {
				display: block;
				@include fs(12rem);
				text-align: center;
				padding-left: 0; }
			@include media-breakpoint-down(xs) {
				@include fs(11rem);
				line-height: 1.3; } }
		&.active {
			&:after {
 }				// border-left-color: darken($white,10%)
			.number {
				background: $red;
				color: $white;
				font-weight: bold; }
			.name {
				color: $main; }
			&.finish {} }
		&.finish {
			.number {
				background: $white;
				// background: darken($white,60%)
				color: $red;
				@include fs(0);
				&:before {
					content: '\f00c';
					font-family: 'fontawesome';
					@include fs(15rem); } } } } }

// BASIC PROPERTIES
@mixin bn-border-radius($pre, $suf, $values...) {
  $top: $pre + "-top-left" + if($suf, "-#{$suf}", "");
  $bottom: $pre + "-bottom-right" + if($suf, "-#{$suf}", "");
  $left: $pre + "-bottom-left" + if($suf, "-#{$suf}", "");
  $right: $pre + "-top-right" + if($suf, "-#{$suf}", "");
  $all: $pre + if($suf, "-#{$suf}", "");
  $values: get_array($values);
  @if check_list($values) {
    @if nth($values, 1) {
      #{$top}: im-rep(#{nth($values, 1)}); }
    @if length($values) == 1 {
      @if nth($values, 1) {
        #{$right}: im-rep(#{nth($values, 1)}); } }
    @else {
      @if nth($values, 2) {
        #{$right}: im-rep(#{nth($values, 2)}); } }
    @if length($values) == 2 {
      @if nth($values, 1) {
        #{$bottom}: im-rep(#{nth($values, 1)}); }
      @if nth($values, 2) {
        #{$left}: im-rep(#{nth($values, 2)}); } }
    @else if length($values) == 3 {
      @if nth($values, 3) {
        #{$bottom}: im-rep(#{nth($values, 3)}); }
      @if nth($values, 2) {
        #{$left}: im-rep(#{nth($values, 2)}); } }
    @else if length($values) == 4 {
      @if nth($values, 3) {
        #{$bottom}: im-rep(#{nth($values, 3)}); }
      @if nth($values, 4) {
        #{$left}: im-rep(#{nth($values, 4)}); } } }
  @else {
    @if length($values) == 1 {
      #{$all}: im-rep(#{$values}); }
    @else if length($values) == 2 {
      #{$top}: im-rep(#{nth($values, 1)});
      #{$right}: im-rep(#{nth($values, 2)});
      #{$bottom}: im-rep(#{nth($values, 1)});
      #{$left}: im-rep(#{nth($values, 2)}); }
    @else if length($values) == 3 {
      #{$top}: im-rep(#{nth($values, 1)});
      #{$right}: im-rep(#{nth($values, 2)});
      #{$bottom}: im-rep(#{nth($values, 3)}); }
    @else if length($values) == 4 {
      #{$top}: im-rep(#{nth($values, 1)});
      #{$right}: im-rep(#{nth($values, 2)});
      #{$bottom}: im-rep(#{nth($values, 3)});
      #{$left}: im-rep(#{nth($values, 4)}); } } }

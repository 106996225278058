.popupwrap {
	@include pos(fix, 0 null 0 0);
	right: 0;
	z-index: 1000;
	@include flexbox;
	justify-content: center;
	align-items: center;
	background: color-rgba($black,0.8);
	.location-popup {
		width: 500px;
		padding: 40px 50px;
		background: $white;
		box-shadow: 0 5px 30px color-rgba($black,0.5);
		@include media-breakpoint-down(sm) {
			padding: 30px; }
		@include media-breakpoint-down(xs) {
			padding: 20px; }
		.sitelogo {
			margin-bottom: 10px;
			a {
				display: block;
				text-align: center; }
			img {
				height: 70px; } }
		.title {
			@include fs(20rem);
			color: $main;
			text-align: center;
			@include media-breakpoint-down(xs) {
				@include fs(16rem); } }
		.des {
			@include fs(13rem);
			text-align: center;
			color: darken($white,40%);
			margin-bottom: 30px; }
		.form-group {
			margin-bottom: 20px;
			&:last-child {
				margin-bottom: 0; }
			label {
				@include fs(14rem);
				color: darken($white,60%);
				margin-bottom: 5px; }
			input, select {
				width: 100%;
				padding: 15px 15px;
				border: 1px solid darken($white,10%); }
			select {
				padding: 14px 15px; } }
		input[type="submit"] {
			width: 100%;
			padding: 0 10px;
			text-align: center;
			border: none;
			background: $pink;
			color: $white;
			text-align: center;
			line-height: 46px; }
		.alert {
			display: block;
			margin: 10px 0 0px; } } }


// CART FOOTER

.cartfooter {
	border-top: 1px solid darken($white,10%);
	// background: darken($white,5%)
	.copyright {
		padding: 15px 0;
		color: darken($white,40%);
		// text-align: center
		@include fs(14rem);
		float: left;
		@include media-breakpoint-down(md) {
			width: 100%;
			float: none;
			text-align: center; } }
	.icon-list {
		float: right;
		padding: 10px 0;
		@include media-breakpoint-down(md) {
			width: 100%;
			float: none;
			text-align: center;
			padding: 0;
			margin-bottom: 20px; }
		ul {
			li {
				display: inline-block;
				img {
					height: 30px; } } } } }

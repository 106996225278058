@import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i|Muli:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i&subset=latin-ext,vietnamese');

// MATERIAL COLOR
$red: #F44336;
$pink: #E91E63;
$purple: #9C27B0;
$deeppurple: #673AB7;
$indigo: #3F51B5;
$blue: #2196F3;
$lightblue: #03A9F4;
$cyan: #00BCD4;
$teal: #009688;
$green: #6e953b;
$lightgreen: #8dc63f;
$lime: #CDDC39;
$yellow: #FFEB3B;
$amber: #FFC107;
$orange: #FF9800;
$deeporange: #FF5722;
$brown: #946b3a;
$lbrown: #d69b28;
$dbrown: #7b2e00;
$grey: #212121;
$bluegrey: #263238;

// CUSTOM COLOR
$white: #fff;
$black: #B1000F;
$lblack: #222;
$main: #333;

$red: #B1000F;
$dred: #be1d2d;
$blue: #346da6;
$yellow: #dfaa55;

$facebook: #3b5998;
$twitter: #55acee;
$pint: #c5232d;
$google: #dd4c3b;
$youtube: #ff0018;
$yahoo: #702b90;

// GRADIENT COLOR
$lred: #ff856a;
$dred: #fe67a2;
$lgreen: #b2e055;
$dgreen: #5ac1c0;
$lpink: #fab17a;
$dpink: #ef7f97;
$lblue: #59e1cb;
$dblue: #32b8e6;
$lpurple: #dc8dff;
$dpurple: #7866f8;

// MATERIAL VARIABLE
@mixin flexbox {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flexbox;
	display: flex; }
@mixin clearfix {
	content: '';
	display: table;
	width: 100%;
	clear: both; }

@mixin mainbutton {
	display: inline-block;
	@include fs(14rem);
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	padding: 0 15px;
	line-height: 40px;
	background: $blue;
	color: $white;
	.fa {
		margin-right: 5px; }
	&:hover {
		background: darken($blue,5%);
		color: $white; } }

@mixin fs($size) {
	font-size: ($size / 16); }
body {
	margin: 0;
	padding: 0;
	@include fs(15rem);
	font-family: 'montserrat';
	color: $main;
	background: $white;
	line-height: 1.5;
	@include media-breakpoint-down(lg) {
 }		// padding-bottom: 40px
	@include media-breakpoint-down(sm) {
		@include fs(13rem); }
	// +media-breakpoint-up(xl)
	// 	max-width: 1440px
	// 	margin: 0 auto
 }	// 	overflow: hidden
html {
	overflow: auto; }
.fullpage {
	overflow: hidden;
	max-width: 1600px;
	margin: 0 auto; }
a {
	@include transition(all,0.3s); }
a, a:hover {
	&:focus, &:active, &:hover {
		outline: none;
		text-decoration: none; } }
input,button, select, textarea {
	@include transition(all,0.3s);
	&:hover, &:active, &:focus {
		outline: none; } }
select {
	-webkit-appearance: menulist-button; }
button {
	border: none;
	background: none;
	margin: 0;
	padding: 0;
	@include transition(all,0.3s); }
img, iframe {
	vertical-align: middle; }
main {
	clear: both;
	&:before, &:after {
		content: '';
		display: table;
		width: 100%;
		clear: both; } }

strong {
	span {
		font-weight: bold; } }

// .flex, .flex-wrap
/// clear: both

header {
	// .container
	// 	+media-breakpoint-down(lg)
	// 		width: 100%
 }	// 		max-width: 100%
.container {
	@include pos(static,null);
	@include media-breakpoint-up(xl) {
		max-width: 1170px;
		width: 100%; }
	@include media-breakpoint-down(md) {
		// max-width: 100%
 } }		// width: 100%

.content {
	line-height: 1.5;
	@include media-breakpoint-down(md) {
		@include fs(14rem);
		line-height: 1.8; }
	ul, ol, p, h1, h2, h3, h4, h5, h6, img, figure {
		margin-bottom: 15px;
		line-height: 1.5;
		&:last-child {
			margin-bottom: 0; }
		@include media-breakpoint-down(xs) {
			margin-bottom: 10px; } }
	strong {
		font-weight: 500; }
	figure {
		margin: 0 auto 15px;
		text-align: center;
		@include media-breakpoint-down(xs) {
			margin-bottom: 10px; }
		img {
			margin-bottom: 5px; }
		figcaption {
			// background: darken($white,5%)
			padding: 5px 0;
			text-align: center;
			@include fs(13rem);
			font-style: italic;
			color: darken($white,60%); } }
	ul {
		padding-left: 15px;
		li {
			@include pos(rel,null);
			&:before {
				content: '';
				display: block;
				width: 4px;
				height: 4px;
				@include bor-rad(50px);
				background: darken($white,60%);
				@include pos(abs, 7px null null -15px); } } } }

.row {}
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl {
	@include transition(all,0.3s);
	@include pos(static,null); }
.col-12 {
	width: 100%; }
.nopadding {
	padding: 0; }
* {
	&:hover, &:active, &:focus {
		outline: none; }
	&:before, &:after {
		font-family: 'fontawesome'; } }
.lnr {
	font-family: 'linearicons-free';
	&:before, &:after {
		font-family: 'linearicons-free'; } }

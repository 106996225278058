html {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent; }

body {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent;
  div {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-weight: normal;
    vertical-align: baseline;
    background: transparent; } }

span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent; }

article, aside, figure, footer, header, nav, section, details, summary {
  display: block; }

html {
  box-sizing: border-box; }

* {
  box-sizing: inherit;
  &:before, &:after {
    box-sizing: inherit; } }

img, object, embed {
  max-width: 100%; }


html {
  overflow-y: scroll; }

ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote {
  &:before, &:after {
    content: '';
    content: none; } }

q {
  &:before, &:after {
    content: '';
    content: none; } }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted #B1000F;
  cursor: help; }

table {
  border-collapse: separate;
  border-spacing: 0; }

th {
  font-weight: bold;
  vertical-align: bottom; }

td {
  font-weight: normal;
  vertical-align: top; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

pre {
  white-space: pre;
  white-space: pre-wrap;
  white-space: pre-line;
  word-wrap: break-word; }

input {
  &[type="radio"] {
    vertical-align: text-bottom; }
  &[type="checkbox"] {
    vertical-align: bottom; } }

.ie7 input[type="checkbox"] {
  vertical-align: baseline; }

.ie6 input {
  vertical-align: text-bottom; }

select, input, textarea {
  font: 99% sans-serif; }

table {
  font-size: inherit;
  font: 100%; }

small {
  font-size: 85%; }

strong {
  font-weight: bold; }

td {
  vertical-align: top;
  img {
    vertical-align: top; } }

sub {
  font-size: 75%;
  line-height: 0;
  position: relative; }

sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  top: -0.5em; }

sub {
  bottom: -0.25em; }


pre, code, kbd, samp {
  font-family: monospace, sans-serif; }


.clickable, label {
  cursor: pointer; }

input {
  &[type=button], &[type=submit], &[type=file] {
    cursor: pointer; } }

button {
  cursor: pointer;
  margin: 0; }


input, select, textarea {
  margin: 0; }


button, input[type=button] {
  width: auto;
  overflow: visible; }

.ie7 img {
  -ms-interpolation-mode: bicubic; }

.clearfix:after {
  content: " ";
  display: block;
  clear: both; }

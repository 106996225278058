
// COLOR


@import "_cartheader";
@import "_cartfooter";
@import "_cartstep";

.cartpage {
	// padding-top: 0
	@include media-breakpoint-down(sm) {
 }		// padding-top: 0
	footer .footertop {
 }		// display: none
	header {
		@include media-breakpoint-down(sm) {
 } }			// display: none
	.container {
		@include media-breakpoint-down(lg) {
			// width: 100%
 } } }			// max-width: 100%


// CART PAGE

.carttitle {
	@include fs(15rem);
	color: $main;
	font-weight: 500;
	line-height: 40px;
	@include pos(rel,null);
	text-transform: uppercase;
	padding-left: 30px;
	// border-bottom: 1px solid $red
	@include media-breakpoint-down(xs) {
		@include fs(14rem);
		padding: 0px 15px;
		padding-left: 40px;
		background: darken($white,5%);
		color: $main;
		line-height: 36px;
		margin: 0; }
	strong {
		color: $main;
		@include media-breakpoint-down(xs) {
			color: $red; } }
	&:before {
		// color: $red
		@include fs(18rem);
		width: 30px;
		line-height: 40px;
		margin-right: 5px;
		text-align: left;
		@include pos(abs, 0 null null 0);
		@include media-breakpoint-down(xs) {
			left: 15px;
			color: $main;
			line-height: 36px; } } }
.cart-page {
	background: $white;
	margin-bottom: 30px;
	// border: 1px solid darken($white,10%)
	// +bor-rad(5px)
	overflow: hidden;
	@include media-breakpoint-down(md) {
		margin-bottom: 20px; }
	@include media-breakpoint-down(xs) {
		padding: 0;
		margin-bottom: 0; }
	.alert {
		margin-top: 10px; }
	label.error {
		@include pos(abs, null 10px 10px null);
		@include fs(0);
		line-height: 1;
		&:before {
			content: '\f071';
			font-family: 'fontawesome';
			@include fs(16rem);
			color: $red; } }
	.carttitle {
		@include media-breakpoint-down(xs);
		&:before {
			content: '\f07a';
			font-family: 'fontawesome';
			margin-right: 5px;
			font-weight: normal; } }
	.cartnotice {
		margin: 0 0 10px;
		@include bor-rad(5px);
		border: 1px solid lighten($red,30%);
		background: lighten($red,40%);
		color: $main;
		padding: 10px 30px 10px 15px;
		@include pos(rel,null);
		@include fs(14rem);
		a {
			color: $red;
			&:hover {
				color: $red; } } }
	.removenotice {
		@include pos(abs, 7px 15px null null);
		cursor: pointer;
		@include fs(16rem);
		color: $red;
		&:before {
			content: '\f057';
			font-family: 'fontawesome'; }
		&:hover {
			color: lighten($red, 20%); } }
	.cart-table {
		@include bor-rad(5px);
		border: 1px solid darken($white,10%);
		width: 100%;
		@include media-breakpoint-down(lg) {
			margin-bottom: 15px; }
		th, td {
			background: none;
			@include media-breakpoint-up(lg) {
				padding: 10px; } }
		th {
			display: none;
			@include media-breakpoint-up(lg) {
				display: table-cell;
				vertical-align: middle;
				font-weight: 500;
				@include fs(13rem);
				text-transform: uppercase;
				background: darken($white,5%);
				border-bottom: 1px solid darken($white,10%);
				&:last-child {
					text-align: right; } } }
		tbody {
			tr {
				@include media-breakpoint-down(md) {
					@include flexbox;
					flex-wrap: wrap;
					border-bottom: 1px solid darken($white,10%);
					&:last-child {
						border-bottom: none; } } }
			td {}
			.product {
				width: 100%;
				padding: 10px;
				@include pos(rel,null);
				@include media-breakpoint-up(lg) {
					width: calc(100% - 200px - 100px - 200px); }
				figure {
					@include flexbox;
					.product-img {
						display: block;
						width: 80px; }
					figcaption {
						width: calc(100% - 80px);
						padding-left: 10px;
						.product-name {
							@include fs(14rem);
							font-weight: 500;
							line-height: 1.5;
							margin-bottom: 5px;
							a {
								color: $black; } }
						.product-des {
							@include fs(12rem);
							color: darken($white,40%); }
						.product-option {
							@include media-breakpoint-up(lg) {
								@include flexbox;
								flex-wrap: wrap; }
							.label {
								@include fs(13rem);
								display: inline-block;
								vertical-align: middle; }
							.product-size {
								// width: 50%
								margin-bottom: 5px;
								@include media-breakpoint-up(lg) {
									margin: 0; }
								select {
									display: inline-block;
									vertical-align: middle;
									@include bor-rad(3px);
									border: 1px solid darken($white,30%);
									color: $black;
 } }									// font-weight: 600
							.product-color {
								@include media-breakpoint-up(lg) {
									margin-left: 15px;
									@include flexbox;
									align-items: center;
									align-content: center; }
								.color-list {
									display: inline-block;
									vertical-align: top;
									@include media-breakpoint-up(lg) {
										@include flexbox;
										align-content: center;
										align-items: center; } }
								a {
									display: inline-block;
									border: 1px solid darken($white,10%);
									padding: 2px;
									&.active {
										border-color: $red; }
									+ a {
										margin-left: 3px; } }
								.color-img {
									width: 14px;
									height: 14px; }
 } }								// width: 50%
						.removeproduct {
							@include pos(abs, 8px 10px null null);
							color: $lred;
							@include fs(0);
							@include media-breakpoint-up(lg) {
								@include fs(13rem);
								@include pos(static,unset);
								margin-top: 10px;
								display: block;
								color: darken($white,40%); }
							&:hover {
								color: $red;
								.material-icons, .fa {
									opacity: 1; } }
							.material-icons, .fa {
								@include fs(16rem);
								line-height: 1;
								color: $red;
								opacity: 0.5; } } } } }
			.price {
				margin-left: 100px;
				width: calc(100% - 200px);
				@include media-breakpoint-up(lg) {
					width: 150px;
					margin-left: 0; }
				.new {
					display: block;
					@include fs(15rem);
					font-weight: 500;
					color: $red;
					line-height: 1;
					@include media-breakpoint-up(lg) {
						color: $black;
						font-weight: 500; } }
				.old {
					display: inline-block;
					@include fs(11rem);
					text-decoration: line-through;
					color: darken($white,40%);
					font-weight: 500;
					line-height: 1;
					@include media-breakpoint-up(lg) {
						font-weight: 400;
						color: darken($white,20%); } }
				.sale {
					display: inline-block;
					@include fs(12rem);
					line-height: 1;
					color: $lred; } }
			.amount {
				margin-bottom: 10px;
				@include media-breakpoint-up(lg) {
					margin-bottom: 0;
					width: 100px; }
				.input-number {
					width: 90px;
					background: darken($white,5%);
					border: none;
					overflow: hidden;
					.btn-spin {
						width: 30px;
						height: 30px;
						line-height: 30px;
						background: darken($white,10%); }
					input {
						line-height: 30px;
						width: 30px; } } }
			.total {
				display: none;
				@include fs(15rem);
				font-weight: 600;
				color: $red;
				line-height: 1;
				@include media-breakpoint-up(lg) {
					display: table-cell;
					width: 150px;
					text-align: right; } } } }
	.totalamount {
		text-align: right;
		// padding: 0 20px
		@include fs(14rem);
		strong {
			color: $red;
			@include fs(24rem); }
		@include media-breakpoint-down(sm) {
			margin-top: 10px;
			@include fs(13rem);
			strong {
				@include fs(18rem); } } }
	.payment-des {
		text-align: right;
		font-style: italic;
		// padding: 0 20px
		margin-bottom: 15px;
		@include fs(13rem);
		color: darken($white,40%);
		@include media-breakpoint-down(xs) {
			@include fs(12rem);
			color: darken($white,40%);
			padding: 0px 15px;
			line-height: 1;
			margin-bottom: 10px; } } }
.product-lastview {
	margin-bottom: 40px;
	.carttitle {
		&:before {
			content: '\f017'; } }
	.cart-lastview-slide {
		border: 1px solid darken($white,10%);
		border-left: none;
		// +bor-rad(5px)
		// padding: 10px
		&:before {
			content: '';
			@include pos(abs, 0 null 0 0);
			width: 1px;
			background: darken($white,10%);
			z-index: 5; }
		.slick-prev, .slick-next {
			@include pos(abs, 50% null null null);
			@include transform(translateY(-50%));
			@include fs(0);
			width: 36px;
			height: 36px;
			line-height: 36px;
			text-align: center;
			@include bor-rad(50px);
			background: darken($white,10%);
			z-index: 5;
			&.slick-disabled {
				opacity: 0; }
			&:before {
				content: '';
				font-family: 'fontawesome';
				@include fs(18rem); } }
		.slick-prev {
			left: 0;
			&:before {
				content: '\f104'; } }
		.slick-next {
			right: 0;
			&:before {
				content: '\f105'; } }
		.item {
			border-left: 1px solid darken($white,10%);
			overflow: hidden; } } }
.cart-page {
	.btn-wrap {
		// margin-bottom: 30px
		@include media-breakpoint-down(md) {
			margin-bottom: 20px; }
		@include media-breakpoint-down(xs) {
			// padding: 0px 15px 10px
			// +flexbox
			// text-align: center
			// +pos(fix, null 0 0 0)
			// z-index: 50
			// border-top: 1px solid darken($white,10%)
 } } }			// box-shadow: 0 0 30px color-rgba($black,0.1)
.btn-back {
	display: block;
	background: darken($white,10%);
	color: $main;
	// +fs(14rem)
	// +bor-rad(5px)
	line-height: 40px;
	padding: 0px 20px;
	float: left;
	text-align: center;
	@include media-breakpoint-down(xs) {
		background: none;
		width: 100%;
		margin-bottom: 0;
		color: darken($white,60%);
		float: none; }
	&:hover {
		background: $gray;
		color: $white; }
	&:before {
		content: '\f104';
		font-family: 'fontawesome';
		margin-right: 15px;
		@include media-breakpoint-down(sm) {
			display: none; } } }
.btn-continue {
	display: block;
	background: $red;
	overflow: hidden;
	color: $white;
	line-height: 40px;
	padding: 0px 20px;
	float: right;
	margin: 0;
	// +bor-rad(5px)
	text-align: center;
	@include media-breakpoint-down(xs) {
		width: 100%;
		margin: 0;
		text-transform: uppercase;
		@include fs(15rem);
		float: none; }
	&:hover {
		color: $white; }
	&:after {
		content: '\f105';
		font-family: 'fontawesome';
		margin-left: 15px;
		@include media-breakpoint-down(sm) {
			display: none; } }
	&:before {
		background: color-rgba($white,0.2); } }

.cartpage {
	.nopadding {
		background: $white;
		+ .nopadding {
			border-left: 1px solid darken($white,10%); } } }

.select-group {
	margin-bottom: 10px;
	input[type="radio"] {
		display: none;
		+ label {
			padding: 5px 0 5px 20px;
			@include pos(rel,null);
			color: darken($white,60%);
			@include transition(all,0.3s);
			@include fs(14rem);
			@include media-breakpoint-down(xs) {
				padding: 0px 0 0 20px; }
			&:hover {
				color: $black; }
			&:before {
				content: '';
				display: block;
				width: 14px;
				height: 14px;
				@include bor-rad(50px);
				@include pos(abs, 7px null null 0);
				border: 1px solid darken($white,40%);
				@include media-breakpoint-down(xs) {
					top: 3px; } }
			&:after {
				content: '';
				display: block;
				width: 14px;
				height: 14px;
				border: 4px solid $red;
				@include pos(abs, 7px null null 0);
				@include bor-rad(50px);
				opacity: 0;
				@include transition(all,0.3s);
				@include media-breakpoint-down(xs) {
					top: 3px; } } }
		&:checked {
			+ label {
				color: $main;
				&:after {
					opacity: 1; } } } } }

.check-group {
	margin-bottom: 10px;
	input {
		display: none;
		+ label {
			color: $main;
			padding-left: 25px;
			@include pos(rel,null);
			&:hover {
				color: $black; }
			&:before {
				content: '';
				display: block;
				width: 16px;
				height: 16px;
				// +bor-rad(3px)
				border: 1px solid darken($white,20%);
				@include pos(abs, 1px null null 0); }
			&:after {
				content: '\f00c';
				font-family: 'fontawesome';
				display: block;
				width: 16px;
				height: 16px;
				line-height: 14px;
				text-align: center;
				// +bor-rad(3px)
				color: $red;
				border: 1px solid darken($white,20%);
				@include pos(abs, 1px null null 0);
				z-index: 1;
				@include transition(all,0.5s);
				opacity: 0; } }
		&:checked {
			+ label {
				&:after {
					opacity: 1; } } } } }

// CART LOGIN
.cart-login {
	// border: 1px solid darken($white,10%)
	// +bor-rad(5px)
	margin-bottom: 20px;
	background: $white;
	// padding: 20px 0
	overflow: hidden;
	@include media-breakpoint-down(md);
	@include media-breakpoint-down(xs) {
		padding: 10px 15px;
		@include fs(13rem); }
	.login-form {
		width: 400px;
		margin: 0 auto;
		@include media-breakpoint-down(xs) {
			width: 100%; }
		.module-title {
			@include fs(14rem);
			font-weight: 500;
			margin-bottom: 10px;
			@include media-breakpoint-down(xs) {
				margin-bottom: 10px; } }
		.form-group {
			margin-bottom: 10px;
			@include media-breakpoint-down(xs) {
				margin-bottom: 10px; }
			.select-group {
				@include media-breakpoint-down(xs) {
					margin-bottom: 10px; } }
			input {
				width: 100%;
				border: 1px solid darken($white,20%);
				padding: 0px 15px;
				line-height: 36px;
				@include fs(14rem);
				@include transition(all,0.3s);
				@include bor-rad(0px);
				@include media-breakpoint-down(xs) {
					line-height: 30px;
					@include fs(13rem); }
				&:hover, &:active, &:focus {
					border-color: darken($white,40%); } } }

		.forget-password {
			display: block;
			@include fs(14rem);
			color: $main;
			margin-bottom: 0px;
			@include media-breakpoint-down(xs) {
				@include fs(13rem);
				margin-bottom: 10px; }
			&:hover {
				color: $red; } }
		.btn-continue {
			display: block;
			float: none;
			text-align: center;
			margin: 20px 0 0;
			@include media-breakpoint-down(xs) {
				margin-top: 10px; } }
		.social-login {
			margin: 20px 0 0;
			padding: 0;
			background: none;
			.module-title {
				font-weight: 700;
				margin-bottom: 10px; }
			a {
				display: block;
				padding: 10px 10px;
				color: $white;
				margin-bottom: 10px;
				font-weight: 500;
				text-transform: uppercase;
				text-align: center;
				@include pos(rel,null);
				@include fs(13rem);
				&:hover {
					filter: brightness(120%); }
				.fa {
					width: 40px;
					height: 30px;
					line-height: 30px;
					@include fs(18rem);
					@include pos(abs, 4px null null 1px);
					text-align: center;
					margin-right: 10px;
					border-right: 1px solid color-rgba($white,0.5); } }
			.facebook {
				background: $facebook; }
			.google {
				background: $google; } } } }

.minicart {
	@include pos(rel,null);
	// margin-bottom: 50px
	// border: 1px solid darken($white,10%)
	// padding: 0 10px
	@include bor-rad(5px);
	overflow: hidden;
	@include media-breakpoint-down(xs) {
		padding: 0; }
	.carttitle {
		margin: 0; }
	.btn-edit {
		@include pos(abs, 10px 20px null null);
		@include fs(0rem);
		color: $red;
		@include media-breakpoint-down(xs) {
			color: $red;
			top: 5px;
			right: 15px; }
		&:before {
			content: '\f040';
			font-family: 'fontawesome';
			@include fs(15rem); }
		&:hover {
			color: $main; } }
	.carttable {
		// border: 1px solid darken($white,10%)
		@include media-breakpoint-down(xs) {
			margin-bottom: 0; }
		.group {
			padding: 10px;
			border-bottom: 1px solid darken($white,10%);
			@include flexbox;
			flex-wrap: wrap;
			@include media-breakpoint-down(xs) {
				padding: 10px 15px; }
			&:last-child {
				border: none; }
			.product {
				width: calc(100% - 100px);
				margin: 0;
				.productimg {
					width: 70px;
					height: 70px;
					display: flex;
					justify-content: center;
					align-items: center;
					float: left;
					border: 1px solid darken($white,10%);
					img {
						height: 70px;
						object-fit: cover; } }
				.productdetail {
					width: calc(100% - 70px);
					float: left;
					padding-left: 10px;
					line-height: 1.5;
					@include media-breakpoint-down(xs) {
						padding-left: 15px; }
					.product-brand {
						@include fs(12rem);
						color: darken($white,40%); }
					.product-name {
						@include fs(14rem);
						font-weight: 500;
						// margin-bottom: 5px
						@include media-breakpoint-down(sm) {
							@include fs(13rem);
							line-height: 1.5;
							margin-bottom: 5px; }
						a {
							color: $main;
							&:hover {
								color: $red; } } } } }
			.amount {
				width: 100px;
				white-space: nowrap;
				text-align: right;
				line-height: 1.5;
				.price {
					font-weight: 400;
					@include fs(13rem);
					margin: 0;
					text-align: right;
					width: 100%;
					float: none;
					color: darken($white,60%); }
				.amount {
					@include fs(12rem);
					color: darken($white,20%);
					margin: 0;
					width: 100%;
					float: none; }
				.total {
					@include fs(15rem);
					margin: 0;
					color: $red;
					width: 100%;
					line-height: 1.5;
					float: none; } }
			.label {
				width: calc(100% - 150px);
				text-align: left;
				@include fs(14rem); }
			.total-price {
				color: $red;
				@include fs(18rem); }
			.desc-deliver {
				@include fs(13rem);
				ul {
					li {
						margin-bottom: 10px;
						&:last-child {
							margin: 0; }
						span {
							color: $red;
							font-weight: bold; } } } }
			.disount {
				ul {
					margin-top: 5px; } }
			.value {
				width: 150px;
				text-align: right;
				// font-weight: 500
				color: $main;
				line-height: 1.5;
				@include fs(14rem);
				&.total {
					color: $red;
					// font-weight: 500
					@include fs(16rem); }
				strong {
					color: $red;
					@include fs(18rem); }
				span {
					display: block;
					width: 100%;
					@include fs(12rem);
					font-style: italic;
					text-align: right;
					color: darken($white,60%); } } } }
	.member-form {
		width: 100%;
		.module-tite {
			font-weight: 700;
			margin-bottom: 10px; }
		.form-group {
			margin-bottom: 10px;
			@include flexbox;
			label {
				@include fs(14rem);
				width: 120px;
				@include flexbox;
				align-items: center;
				align-content: center; }
			input {
				width: calc(100% - 120px);
				@include bor-rad(0px);
				border: 1px solid darken($white,10%);
				padding: 8px 15px;
				&::-webkit-input-placeholder {
					opacity: 0.5; } } }
		input[type="submit"] {
			display: inline-block;
			border: none;
			color: $white;
			@include bor-rad(0px);
			background: $red;
			padding: 8px 15px;
			float: right; } } }

.help {
	display: inline-block;
	margin-left: 10px;
	color: darken($white,40%);
	@include fs(18rem);
	@include pos(rel,null);
	@include media-breakpoint-down(xs) {
		@include fs(16rem); }
	.help-tip {
		@include pos(abs, 50% 0 null null);
		min-width: 140px;
		@include fs(12rem);
		padding: 5px 10px;
		background: $grey;
		@include transform(translateX(calc(100% + 10px))translateY(-50%));
		opacity: 0;
		@include transition(all,0.3s);
		margin-top: 10px;
		cursor: pointer;
		&:before {
			content: '';
			display: block;
			width: 0;
			height: 0;
			border: 7px solid;
			border-color: transparent $grey transparent transparent;
			@include pos(abs, 50% null null 1px);
			@include transform(translateY(-50%)translate(-100%));
			z-index: 10; } }
	&:hover {
		color: $main;
		.help-tip {
			opacity: 1;
			margin-top: 0;
			color: $main;
			&:hover {
				color: $main; } } } }

.receive-address, .export-bill, .coupon, .shipping-kind, .payment-info {
	margin-bottom: 20px;
	@include media-breakpoint-down(xs) {
		padding: 0;
		margin-bottom: 0; }
	.cart-form {
		@include bor-rad(0px);
		border: 1px solid darken($white,10%);
		background: darken($white,2%);
		padding: 15px;
		@include media-breakpoint-down(xs) {
			padding: 10px 15px; }
		.module-title {
			@include fs(14rem);
			margin-bottom: 15px; }
		.des {
			@include fs(14rem);
			color: darken($white,40%); }
		.form-group {
			width: 100%;
			margin-bottom: 15px;
			@include flexbox;
			flex-wrap: wrap;
			@include media-breakpoint-down(xs) {
				margin-bottom: 5px;
				@include flexbox; }
			.des {
				@include fs(11rem);
				color: darken($white,40%); }
			.label, .input, .hr {
				@include media-breakpoint-down(xs) {
					display: block; } }
			.label {
				width: 200px;
				@include fs(14rem);
				@include flexbox;
				align-items: center;
				align-content: center;
				@include media-breakpoint-down(sm) {
					width: 150px; }
				@include media-breakpoint-down(xs) {
					width: 100px;
					height: 29px;
					@include flexbox;
					align-items: center;
					// margin-bottom: 5px
					@include fs(13rem); }
				label {
					@include fs(13rem);
					font-weight: 400; } }
			.input {
				width: calc(100% - 200px);
				@include media-breakpoint-down(sm) {
					width: calc(100% - 150px); }
				@include media-breakpoint-down(xs) {
					width: calc(100% - 100px); }
				input, textarea, select {
					width: 100%;
					border: 1px solid darken($white,20%);
					padding: 10px 15px;
					@include fs(14rem);
					@include transition(all,0.3s);
					@include bor-rad(0px);
					@include media-breakpoint-down(xs) {
						padding: 5px 10px;
						@include fs(13rem); }
					&::-webkit-input-placeholder {
						opacity: 0.3; }
					&:hover, &:active, &:focus {
						border-color: $red; } }
				select {
					padding: 9px 15px;
					@include media-breakpoint-down(xs) {
						padding: 6px 10px; } }
				&.phone {
					input {
						display: inline-block;
						vertical-align: middle;
						width: 50%;
						@include media-breakpoint-down(xs) {
							display: block;
							width: 100%; } }
					.des {
						display: inline-block;
						vertical-align: middle;
						width: 45%;
						padding-left: 10px;
						@include media-breakpoint-down(xs) {
							display: block;
							width: 100%;
							padding-left: 0;
							margin-top: 5px; } } } }
			&.receive-place {
				margin-top: 20px;
				margin-bottom: 0;
				.label {
					width: 100%;
					label {
						@include fs(14rem);
						font-weight: 500;
						text-transform: uppercase;
						margin-bottom: 10px; } }
				.input {
					width: 100%;
					.radio {
						label {
							@include fs(14rem); } } } } }
		.hr {
			display: block;
			width: calc(100% - 200px);
			height: 1px;
			background: darken($white,10%);
			float: right;
			clear: both;
			margin: 10px 0 20px;
			@include media-breakpoint-down(sm) {
				width: calc(100% - 150px); }
			@include media-breakpoint-down(xs) {
				width: 100%;
				margin: 0px 0 5px; } }

		.user-info {
			padding: 10px 0 15px;
			display: none; }

		.input-coupon {
			margin-bottom: 20px;
			@include flexbox;
			@include media-breakpoint-down(xs) {
				margin-bottom: 5px; }
			+ .des {
				@include fs(13rem);
				@include media-breakpoint-down(xs) {
					@include fs(11rem); } }
			input {
				width: 40%;
				border: 1px solid darken($white,20%);
				border-right: none;
				padding: 10px 15px;
				@include fs(14rem);
				@include bor-rad(0px);
				@include transition(all,0.3s);
				float: left;
				@include media-breakpoint-down(xs) {
					padding: 5px 10px;
					@include fs(13rem); }
				&::-webkit-input-placeholder {
					opacity: 0.3; }
				&:hover, &:active, &:focus {
					border-color: $red; }
				@include media-breakpoint-down(xs) {
					width: calc(100% - 79px); } }
			.btn-submitcoupon {
				padding: 9px 20px 8px;
				background: $red;
				color: $white;
				// text-transform: uppercase
				@include fs(14rem);
				@include transition(all,0.3s);
				@include bor-rad(0);
				text-align: center;
				white-space: nowrap;
				@include media-breakpoint-down(xs) {
					padding: 0 15px;
					line-height: 27px;
					@include fs(13rem); }
				&:hover {
					background: darken($red,10%); } } } } }


.receive-address {
	.carttitle {
		&:before {
			content: '\f041';
			font-family: 'fontawesome';
 } }			// +pos(abs, 7px null null 15px)
	.check-group {
		label {
			@include fs(14rem); }
		@include media-breakpoint-down(xs) {
			margin-bottom: 5px; } } }

.shipping-kind {
	.carttitle {
		&:before {
			content: '\f0d1';
			font-family: 'fontawesome';
			// +pos(abs, 7px null null 10px)
 } }			// +fs(18rem)
	.cart-form {
		.select-group {
			&:last-child {
				margin-bottom: 0; } }
		.des {
			margin-left: 25px; }
		.popup-des {
			display: none;
			// background: darken($white,10%)
			background: darken($white,5%);
			padding: 10px 15px;
			margin-left: 30px;
			@include bor-rad(5px);
			@include fs(14rem);
			margin-bottom: 15px;
			@include pos(rel,null);
			&:before {
				content: '';
				display: block;
				width: 0;
				height: 0;
				border: 7px solid;
				border-color: transparent transparent darken($white,5%) transparent;
				@include pos(abs, 0 null null 10px);
				@include transform(translateY(-100%)); }
			p {
				margin-bottom: 5px; }
			ul {
				list-style-type: disc;
				padding-left: 20px; } }
		#ship1-popup {
			display: block; } } }

.export-bill {
	.carttitle {
		@include pos(rel,null);
		padding-left: 0px;
		&:before {
			content: ''; }
		.check-group {
			input {
				+ label {
					padding-left: 30px;
					@include fs(16rem);
					font-weight: 500;
					color: $red;
					@include media-breakpoint-down(xs) {
						@include fs(14rem);
						line-height: 1.3; }
					&:before {
						top: 11px;
						@include media-breakpoint-down(xs) {
							top: 0; } }
					&:after {
						top: 11px;
						background: $red;
						color: $white;
						border-color: $red;
						@include media-breakpoint-down(xs) {
							top: 0; } } } } } }
	.title {
		margin-bottom: 15px; }
	.bill-form {
		display: none;
		.des {} } }
.coupon {
	.carttitle {
		@include pos(rel,null);
		.help {
			@include pos(abs, 0px 15px null null);
			.help-tip {
				min-width: 200px; }
			&:hover {
				color: $main; } } }
	.des {
		@include fs(13rem);
		margin-bottom: 5px;
		@include media-breakpoint-down(xs) {
			@include fs(13rem); } }
	.cart-form {
		.module-title {
			margin-bottom: 10px;
			@include media-breakpoint-down(xs) {
				@include fs(12rem);
 } } }				// margin-bottom: -20px
	.btn-continue {
		@include media-breakpoint-down(xs) {
			margin-top: 0; } } }


.payment-info {
	margin-bottom: 30px;
	@include media-breakpoint-down(xs) {
		margin-bottom: 0; }
	.payment-list {
		.nav-tabs {
			// margin: 0 -3px
			padding: 0;
			border: none;
			@include flexbox;
			margin: 0;
			// flex-wrap: wrap
			// border-bottom: 1px solid $red
			.nav-item {
				width: 33%;
				float: none;
				margin: 0;
				// padding: 0px 3px
				+ .nav-item {
					margin-left: 6px; }
				.nav-link {
					display: block;
					border: 1px solid darken($white,10%);
					border-bottom-color: $red;
					background: darken($white,3%);
					@include bor-rad(0);
					height: 80px;
					margin-bottom: -1px;
					&.tragop {
						border-bottom-color: darken($white,10%);
						&.active {
							border-bottom-color: $white; } }
					.method-img {
						@include flexbox;
						height: 30px;
						justify-content: center;
						align-items: center;
						filter: grayscale(100%);
						img {
							max-width: 100%;
							max-height: 100%; } }
					.method-name {
						@include fs(12rem);
						text-align: center;
						line-height: 1.3;
						color: darken($white,40%);
						margin-bottom: 5px; }
					&.active {
						border-color: $red;
						border-bottom-color: $white;
						background: $white;
						.method-img {
							filter: grayscale(0); }
						.method-name {
							color: $main; } } } } }
		.tab-pane {
			border: 1px solid darken($white,10%);
			padding: 15px;
			margin-bottom: 10px;
			&.active {
				border-color: $red; } } }
	.cart-form {
		.payment-method {
			width: 100%;
			margin-bottom: 10px;
			@include media-breakpoint-down(xs) {
				margin-bottom: 10px; }
			@include media-breakpoint-down(xs) {
				// width: 33.33%
				margin: 0;
				// height: 100%
				@include pos(rel,null); }
			&:last-child {
				margin-bottom: 0; }
			.select-group {
				display: block;
				padding: 0;
				margin: 0;
				@include media-breakpoint-down(xs) {
					margin: 0;
					height: 100%; }
				input {
					+ label {
						display: block;
						width: 100%;
						@include fs(0);
						padding: 0 0 0 20px;
						@include media-breakpoint-down(xs) {
							padding: 0;
							height: 100%; }
						&:before, &:after {
							top: 50%;
							@include transform(translateY(-50%));
							@include media-breakpoint-down(xs) {
								display: none; } }
						.method-info {
							display: flex;
							flex-wrap: wrap;
							background: $white;
							// width: calc(100% - 30px)
							width: 100%;
							border: 1px solid darken($white,15%);
							@include bor-rad(0px);
							@include transition(all,0.3s);
							@include media-breakpoint-down(xs) {
								height: 100%; }
							&:after {
								content: '';
								display: table;
								width: 100%;
								height: 0;
								clear: both; }
							.method-img {
								display: flex;
								justify-content: center;
								align-items: center;
								width: 85px;
								min-height: 85px;
								padding: 10px 0;
								@include transition(all,0.3s);
								@include media-breakpoint-down(xs) {
									width: 100%;
									height: 70px;
									min-height: unset;
									img {
										max-width: 100%;
										max-height: 100%; }
									// border-right: none
 } }									// border-bottom: 1px solid darken($white,10%)
							.method-detail {
								padding: 10px;
								width: calc(100% - 85px);
								border-left: 1px solid darken($white,15%);
								@include media-breakpoint-down(xs) {
									width: 100%;
									// text-align: center
 }									// border: none
								.method-name {
									@include fs(14rem);
									font-weight: 500;
									@include media-breakpoint-down(xs) {
										@include fs(13rem); } }
								.method-des {
									@include fs(13rem);
									color: darken($white,40%);
									@include media-breakpoint-down(xs) {
										@include fs(12rem); } }
								.term {
									padding: 10px 0;
									h3 {
										font-weight: 500;
										color: $main;
										margin-bottom: 10px; }
									.scroll {
										max-height: 100px;
										overflow: auto;
										// padding: 10px
										@include bor-rad(3px);
 } } } } }										// background: lighten($red,60%)
					&:checked {
						+ label {
							.method-info {
								border-color: $red;
								.method-img {
									border-color: $red; } } } } }
				.agree {
					@extend .check-group;
					margin-top: 10px;
					margin-bottom: 30px;
					padding-left: 20px;
					label {
						@include fs(14rem);
						&:before, &:after {
							@include transform(translateY(0%)); } } } } } } }

.destination-address {
	@include pos(rel,null);
	padding: 0px 10px;
	// +bor-rad(5px)
	// border: 1px solid darken($white,10%)
	// margin-bottom: 30px
	.btn-edit {
		@include pos(abs, 10px 20px null null);
		@include fs(0rem);
		color: $red;
		&:before {
			content: '\f040';
			font-family: 'fontawesome';
			@include fs(15rem); }
		&:hover {
			color: $main; } }
	.address-info {
		padding: 15px;
		background: darken($white,3%);
		.name {
			font-weight: 500;
			margin-bottom: 10px;
			@include fs(14rem);
			color: $black; }
		.phone, .address {
			@include fs(13rem);
			color: darken($white,60%); }
		.phone {
			font-weight: bold;
			color: $red; } } }

.thankyou {
	text-align: center;
	@include media-breakpoint-down(md) {
		display: none;
		margin-bottom: 20px;
		padding: 20px 0; }
	img {
 } }		// width: 100%
.payment-success {
	.notice {
		color: $main;
		background: lighten($green,50%);
		border: 1px solid lighten($green,20%);
		padding: 10px 10px;
		@include bor-rad(5px);
		@include pos(rel,null);
		@include flexbox;
		align-items: center;
		margin-bottom: 15px;
		.fa {
			content: '\f058';
			font-family: 'fontawesome';
			color: $green;
			@include fs(20rem);
			line-height: 1; }
		span {
			width: calc(100% - 40px);
			margin-left: 10px;
			font-weight: 500;
			text-transform: uppercase;
			@include fs(14rem);
			color: $green; } }
	.letter {
		@include fs(14rem);
		margin-bottom: 15px;
		a {
			color: $red;
			font-weight: 500;
			&:hover {
				text-decoration: underline; } } }
	.cart-info {
		border: 1px solid darken($white,10%);
		@include bor-rad(5px);
		margin-bottom: 15px;
		@include media-breakpoint-down(sm) {
			display: none; }
		.info-overview {
			@include flexbox;
			.info-col {
				width: 33.33%;
				padding: 10px;
				@include fs(13rem);
				@include media-breakpoint-down(md) {
					@include fs(12rem); }
				strong {
					font-weight: 500; }
				&:nth-child(2) {
					text-align: center; }
				&:nth-child(3) {
					text-align: right;
					strong {
						color: $red; } } } }
		.cart-finish-table {
			width: 100%;
			border-top: 1px solid darken($white,10%);
			th {
				border-bottom: 1px solid darken($white,10%);
				background: darken($white,3%);
				font-weight: 500; }
			td {
				border-bottom: 1px solid darken($white,10%); }
			th, td {
				padding: 5px 10px;
				@include fs(13rem);
				white-space: nowrap;
				@include media-breakpoint-down(md) {
					@include fs(12rem); }
				&:nth-child(1) {
					width: 20%; }
				&:nth-child(2) {
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden; }
				&:nth-child(3) {
					width: 20%; }
				&:nth-child(4) {
					width: 15%;
					text-align: right; } } }
		.total-overview {
			@include flexbox;
			.overview-col {
				width: 33.33%;
				padding: 10px;
				@include media-breakpoint-down(md) {
					@include fs(12rem); }
				&:nth-child(2) {
					text-align: center; }
				&:nth-child(3) {
					text-align: right; }
				strong {
					color: $red; } } } }
	.receive-address {
		@include bor-rad(5px);
		border: 1px solid darken($white,10%);
		padding: 10px;
		@include fs(14rem);
		@include media-breakpoint-down(md) {
			@include fs(12rem); }
		.title {
			font-weight: 700;
			margin-bottom: 5px; } } }
// CART PANEL

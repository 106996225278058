main {
	&:after {
		content: '';
		display: block;
		height: 95px;
		background: $white;
		@include pos(rel,null);
		z-index: 1; } }
footer {
	background: url(../img/footerbg.jpg) center bottom no-repeat;
	background-size: cover;
	.footer-sitemap {
		padding: 30px 0 15px;
		border-bottom: 1px solid color-rgba($white,0.1);
		.footer-col {
			margin-bottom: 15px; }
		.title {
			@include fs(16rem);
			text-transform: uppercase;
			// font-weight: 500
			color: $white;
			margin-bottom: 25px;
			// text-align: center
			&:after {
				content: '';
				display: block;
				width: 60px;
				height: 1px;
				background: $red;
				// margin-top: 10px
				margin: 10px auto 0; } }
		.footerlink {
			// text-align: center
			ul {
				// margin-top: -10px
				li {
					// padding: 10px 0
					// border-bottom: 1px dashed darken($white,40%)
					margin-bottom: 10px;
					@include fs(15rem);
					line-height: 1.5;
					&:last-child {
						border-bottom: none; }
					a {
						color: darken($white,40%);
						&:hover {
							color: $white; } } } } } }
	.footer-contact {
		background: $white;
		padding: 30px 30px;
		margin-top: -50px;
		border: 1px solid color-rgba($black,0.1);
		@include pos(rel,null);
		z-index: 1;
		box-shadow: 0 20px 20px color-rgba($black,0.45);
		@include media-breakpoint-down(lg) {
			padding: 15px;
			margin-top: -30px; }
		.contact-col {
			@include flexbox;
			justify-content: space-between;
			@include media-breakpoint-down(md) {
				flex-wrap: wrap; }
			figure {
				// width: 280px
				@include flexbox;
				align-items: center;
				align-content: center;
				.icon {
					width: 50px;
					height: 50px;
					@include flexbox;
					justify-content: center;
					align-items: center;
					@include media-breakpoint-down(lg) {
						width: 40px;
						height: 40px; }
					img {
						@include media-breakpoint-down(lg) {
							max-width: 100%;
							max-height: 100%; } } }
				figcaption {
					width: calc(100% - 50px);
					padding-left: 10px;
					@include flexbox;
					flex-wrap: wrap;
					align-items: center;
					align-content: center;
					@include media-breakpoint-down(lg) {
						width: calc(100% - 40px); }
					.title {
						width: 100%;
						@include fs(15rem);
						font-weight: 500;
						text-transform: uppercase;
						line-height: 1.2;
						@include media-breakpoint-down(lg) {
							@include fs(14rem); }
						@include media-breakpoint-down(md); }

					.des {
						width: 100%;
						@include fs(13rem);
						color: darken($white,40%);
						@include media-breakpoint-down(lg) {
							@include fs(12rem); } } } }
			.hotline {
				// width: calc(100% - 280px)
				@include media-breakpoint-down(md) {
					padding-left: 50px; }
				a {
					color: $red;
					@include fs(32rem);
					font-weight: 700;
					@include media-breakpoint-down(lg) {
						@include fs(24rem); } } } } }
	.footer-support {
		padding: 30px 0;
		@include flexbox;
		border-bottom: 1px solid color-rgba($white,0.1);
		@include media-breakpoint-down(sm) {
			flex-wrap: wrap;
			justify-content: center; }
		.support-col {
			width: 20%;
			@include media-breakpoint-down(sm) {
				width: 50%; }
			a {
				@include flexbox;
				flex-wrap: wrap;
				justify-content: center;
				.icon {
					width: 80px;
					height: 80px;
					@include flexbox;
					justify-content: center;
					align-items: center;
					background: $red;
					@include bor-rad(50px);
					@include transition(all,0.3s);
					img {
						@include transition(all,0.3s); } }
				.text {
					width: 100%;
					text-align: center;
					@include fs(13rem);
					color: darken($white,60%);
					padding: 5px 30px;
					@include transition(all,0.3s);
					@include media-breakpoint-down(md) {
						padding: 5px 10px; } }
				&:hover {
					.icon {
						background: $white;
						img {
							filter: invert(100%); } }
					.text {
						color: $white; } } } } }

	.footer-main {
		padding: 30px 0 15px;
		.footer-col {
			margin-bottom: 15px; }
		.title {
			@include fs(16rem);
			text-transform: uppercase;
			// font-weight: 500
			color: $white;
			margin-bottom: 25px;
			&:after {
				content: '';
				display: block;
				width: 60px;
				height: 1px;
				background: $red;
				margin-top: 10px;
				@include media-breakpoint-down(sm); } }
		.contact-info {
			@include fs(13rem);
			color: darken($white,40%);
			p {
				margin-bottom: 10px; }
			ul {
				li {
					margin-bottom: 5px;
					@include pos(rel,null);
					padding-left: 20px;
					.fa {
						@include pos(abs, 3px null null 0); }
					a {
						color: darken($white,40%);
						&:hover {
							color: $white; } } } } }
		.footerlink {
			ul {
				margin-top: -10px;
				li {
					padding: 10px 0;
					border-bottom: 1px dashed darken($white,40%);
					@include fs(13rem);
					line-height: 1.5;
					&:last-child {
						border-bottom: none; }
					a {
						color: darken($white,40%);
						&:hover {
							color: $white; } } } } }
		.subscribe {
			margin-bottom: 30px;
			.module-title {
				@include fs(13rem);
				color: darken($white,40%);
				margin-bottom: 10px; }
			.subscribefrm {
				@include flexbox;
				@include bor-rad(3px);
				overflow: hidden;
				background: color-rgba($white,0.2);
				input {
					width: calc(100% - 40px);
					background: none;
					border: none;
					padding: 0px 20px;
					color: $white; }
				.subscribebutton {
					@include fs(0);
					@include flexbox;
					justify-content: center;
					align-items: center;
					align-content: center;
					width: 40px;
					height: 40px;
					line-height: 40px;
					background: $red;
					color: $white;
					&:before {
						content: '\f00c';
						@include fs(18rem); } } } }
		.footer-icon {
			@include flexbox;
			flex-wrap: wrap;
			align-items: center;
			align-content: center;
			a {
				display: block;
				width: 50%; } } }
	.footer-nav {
		border-top: 1px solid color-rgba($white,0.1);
		@include flexbox;
		justify-content: center;
		@include media-breakpoint-down(sm) {
			flex-wrap: wrap; }
		a {
			display: block;
			padding: 10px 25px;
			color: $white;
			@include fs(13rem);
			font-weight: 300;
			@include media-breakpoint-down(md) {
				padding: 10px;
				text-align: center; }
			@include media-breakpoint-down(sm) {
				width: 100%; }
			&:hover {
				color: $red; } } }
	.footer-bottom {
		border-top: 1px solid color-rgba($white,0.1);
		.copyright {
			color: darken($white,60%);
			padding: 15px 0;
			text-align: center;
			@include fs(13rem); } } }
.up {
	@include pos(fix, null 20px 20px null);
	width: 40px;
	height: 40px;
	@include bor-rad(50px);
	background: $red;
	color: $white;
	z-index: 50;
	@include flexbox;
	justify-content: center;
	align-items: center;
	@include fs(20rem);
	cursor: pointer;
	@include transform(translateY(50%));
	opacity: 0;
	visibility: 0;
	@include transition(all,0.5s);
	@include media-breakpoint-down(md) {
		bottom: 70px;
		right: 15px; }
	&:before {
		content: '\f106'; }

	&:hover {
		background: darken($red,10%); }
	&.active {
		@include transform(translateY(0));
		opacity: 1;
		visibility: 1; } }

// LOGIN, SIGNUP, RECOVER PW

.login-panel {
	background: $white;
	padding: 0 15px;
	margin-bottom: 40px;
	box-shadow: 0px 3px 30px color-rgba($black,0.1);
	@include bor-rad(0);
	border: 1px solid darken($white,10%); }
.signup-form, .login-form {
	width: 100%;
	.headtitle {
		margin-bottom: 10px;
		color: $cyan;
		@include fs(15rem);
		line-height: 30px;
		font-weight: 700;
		border-bottom: 1px solid darken($white,10%); }
	.panel-heading {
		margin-bottom: 5px;
		color: darken($white,60%); }
	.form-group {
		margin-bottom: 10px;
		label {
			@include fs(14rem);
			margin-bottom: 5px;
			line-height: 36px; }
		input, select {
			width: 100%;
			@include bor-rad(0);
			font-size: 0.9rem;
			border: 1px solid darken($white,15%);
			padding: 0px 15px;
			line-height: 36px;
			&:hover, &:active, &:focus {
				border-color: darken($white,30%); } }
		select {
			padding: 10px 15px; }
		.fa.fa-exclamation {
			top: 42px;
			right: 10px; } }
	.btnwrap {
		text-align: center;
		padding: 10px 0; }
	input[type="submit"] {
		display: block;
		width: 100%;
		line-height: 36px;
		padding: 0px 20px;
		@include bor-rad(0px);
		background: $gray;
		color: $white;
		font-weight: 300 !important;
		@include fs(14rem);
		border: none;
		text-transform: uppercase;
		&:hover {
			background: darken($gray,10%); } }
	.social-login {
		background: darken($white,5%);
		padding: 10px;
		margin: 0px -15px;
		.des {
			font-weight: 700;
			color: $main; }
		.social {
			a {
				display: inline-block;
				+ a {
					margin-left: 5px; }
				.fa {
					display: block;
					width: 36px;
					line-height: 36px;
					height: 36px;
					text-align: center;
					color: $white;
					&.fa-facebook {
						background: $facebook; }
					&.fa-google-plus {
						background: $google; }
					&.fa-yahoo {
						background: $yahoo; } } } } } }
html body .RadInput_Default .riTextBox {
	width: 100% !important;
	@include bor-rad(0);
	border: 1px solid darken($white,15%) !important;
	// padding: 10px 15px !important
	padding: 0px 15px !important;
	line-height: 40px !important;
	font-size: 0.9rem !important; }

.login-form {
	.form-group {
		width: 100%;
		@include pos(rel,null);
		label {
			width: 40px;
			line-height: 40px;
			text-align: center;
			@include fs(18rem);
			color: $gray;
			@include pos(abs, 0 null null 0);
			.fa {
				color: $gray !important;
				&:before {
					line-height: 1; } } }
		input {
			padding-left: 40px; }
		a {
			color: darken($white,60%);
			margin-right: 10px;
			&:hover {
				color: $gray; } }
		&.forget {
			@extend .check-group;
			label {
				width: auto;
				line-height: 1.5;
				@include fs(13rem);
				@include pos(static,null);
				color: darken($white,60%);
				&:hover {
					color: $gray; } } } }

	input[type="submit"] {
		padding: 0px 20px;
		border: none; }
	.btn-login {
		text-align: center;
		background: none;
		&:before {
			display: none; } } }

.signup-panel {
	background: $white;
	margin-bottom: 40px;
	.col-xs-12 {
		padding: 0 !important; } }
.signup-form {
	padding: 0px 15px;
	.form-group {
		@include flexbox;
		label {
			margin-bottom: 0;
			@include fs(14rem);
			width: 140px;
			line-height: 30px; }
		.settingcontrol {
			width: calc(100% - 130px);
			line-height: 30px;
			input {
				line-height: 30px;
				padding: 0px 10px; }
			select {
				padding: 6px 10px; } } }
	.gender-select {
		ul {
			margin-top: 3px;
			li {
				display: inline-block;
				label {
					line-height: 1.5; } } } }
	.checkbox-button {
		label {
			width: auto;
			line-height: 1.5; } }
	.btnwrap {
		margin-bottom: 15px; } }
.signupnote {
	height: 100%;
	background: $gray; }
// USER SIDEBAR

.user-page {
	margin-bottom: 0;
	// .row
	// 	margin: 0px -5px
	// .col-xs-12
 }	// 	padding: 0px 5px
.user-sidebar {
	@include pos(rel,null);
	margin-top: 0;
	margin-bottom: 30px;
	@include media-breakpoint-down(md) {
		&:after {
			content: '\f107';
			font-family: 'fontawesome';
			@include pos(abs, 50% 15px null null);
			@include transform(translateY(-50%));
			color: $black;
			@include fs(24rem); } }
	.userinfo {
		// border: 1px solid darken($white,10%)
		background: $black;
		@include bor-rad(0);
		overflow: hidden;
		figure {
			&:after {
				content: '';
				display: table;
				width: 100%;
				clear: both; }
			@include flexbox;
			flex-wrap: wrap;
			.user-img {
				// display: none
				width: 80px;
				float: left;
				padding: 15px;
				@include media-breakpoint-down(lg) {
					width: 60px; } }
			figcaption {
				width: calc(100% - 80px);
				@include flexbox;
				flex-wrap: wrap;
				align-items: center;
				align-content: center;
				@include media-breakpoint-down(lg) {
					padding: 7px 15px; }
				.title {
					color: $white;
					opacity: 0.8;
					font-weight: 500;
					@include fs(12rem);
					width: 100%; }
				.user-name {
					text-transform: uppercase;
					font-weight: 600;
					// +fs(16rem)
					@include fs(13rem);
					color: $white;
					width: 100%; }
				.user-rank {
					display: none;
					@include fs(13rem);
					font-weight: 500;
					color: color-rgba($white,0.8);
					.fa {
						color: $amber;
						// margin-right: 5px
						@include fs(16rem); } } } }
		.btn-muasi {
			margin: 0px 15px 15px;
			display: block;
			background: darken($white,10%);
			text-align: center;
			text-transform: uppercase;
			color: $gray;
			font-weight: 700;
			@include bor-rad(0);
			padding: 5px 0;
			box-shadow: 0 3px 10px color-rgba($black,0.3); } } }
.user-sidelink {
	margin-bottom: 30px;
	ul {
		border-right: 1px solid darken($white,10%);
		li {
			display: block;
			&:before {
				display: none; }
			a {
				display: block;
				line-height: 36px;
				// font-weight: 700
				color: $main;
				text-transform: uppercase;
				@include pos(rel,null);
				@include fs(13rem);
				@include media-breakpoint-down(md) {
					@include fs(13rem); }
				&:after {
					content: '';
					display: block;
					width: 2px;
					@include pos(abs, 0 -1px 100% null);
					background: $main;
					@include transition(all,0.5s); } }
			&:hover {
				a {
					color: $black;
					&:after {
						bottom: 0; } } }
			&.active {
				a {
					color: $black;
					font-weight: 500;
					&:after {
						bottom: 0; } } } } } }

// USER PANEL

.user-page {
	// margin-top: 40px
	margin-top: 0;
	// padding: 15px
	background: $white;
	@include pos(rel,null);
	// margin-bottom: 40px
	@include media-breakpoint-down(md) {
		margin-top: 0; }
	.postname {
		@include fs(18rem);
		text-transform: uppercase;
		font-weight: 600;
		margin-bottom: 30px;
		border-left: 3px solid $gray;
		padding-left: 10px; }
	.posttitle {
		@include fs(15rem);
		line-height: 1.5;
		margin: 0 0 10px;
		min-height: 27px;
		font-weight: 500;
		// border-left: 0
		// padding-left: 0
		// &:before
		// 	content: '//'
		// 	color: darken($gray,5%)
 }		// 	margin-right: 10px
	.link {
		@include fs(13rem);
		color: $red;
		background: lighten($red,45%);
		border: 1px solid lighten($red,30%);
		padding: 3px 10px;
		// font-weight: 500
		@include bor-rad(0);
		@include pos(abs, 3px 0 null null);
		&:after {
			content: '\f105';
			font-family: 'fontawesome';
			margin-left: 10px; }
		&:hover {
			color: $white;
			background: $red;
			border-color: $red; } }
	.btn-addaddr {
		@include fs(13rem);
		color: $red;
		background: lighten($red,45%);
		border: 1px solid lighten($red,30%);
		padding: 3px 10px;
		// font-weight: 600
		@include bor-rad(0);
		@include pos(abs, 3px 0 null null);
		&:after {
			content: '\f044';
			font-family: 'fontawesome';
			margin-left: 10px;
			font-weight: normal; }
		&:hover {
			color: $white;
			background: $red;
			border-color: $red; } }
	.table {
		@include fs(14rem);
		background: $white;
		margin-bottom: 0;
		border: 1px solid darken($white,10%);
		@include bor-rad(0);
		overflow: hidden;
		color: darken($white,60%);
		a {
			color: $main;
			// font-weight: 500
			// &:before
			// 	content: '\f00e'
			// 	font-family: 'fontawesome'
			// 	margin-right: 5px
			&:hover {
				color: $red; } }
		.point {
			// font-weight: 500
			color: $gray; }
		.link-detail {
			color: $gray;
			font-weight: normal;
			margin-left: 10px;
			&:hover {
				color: $red;
 } }				// text-decoration: underline
		th {
			border: none;
			background: darken($white,5%);
			color: $main;
			white-space: nowrap;
			border-bottom: 1px solid darken($white,10%); }
		td {
			border: none;
			@include fs(13rem);
			vertical-align: middle;
			.alert-success, .alert-warning, .alert-primary, .alert-info {
				background: none;
				font-weight: 500;
				filter: brightness(150%); } }
		th, td {
			white-space: nowrap;
			padding: 7px 15px;
			&:nth-child(3) {
				white-space: normal;
				@include media-breakpoint-down(sm) {
					white-space: nowrap; } } }
		tr {
			&:nth-child(even) {
				background: darken($white,3%); } } } }
.shipping-progress {
	@include flexbox;
	margin-bottom: 30px;
	.step {
		width: 20%;
		text-align: center;
		padding: 0px 20px;
		@include pos(rel,null);
		.icon {
			width: 60px;
			height: 60px;
			@include bor-rad(50px);
			line-height: 54px;
			margin: 0px auto 30px;
			@include fs(24rem);
			color: darken($white,40%);
			border: 2px solid darken($white,20%);
			&:after {
				content: '';
				display: block;
				width: 100%;
				height: 4px;
				background: darken($white,20%);
				margin-top: 20px;
				@include pos(abs, null 0 null 0);
 } }				// +transform(translateX(-50%))
		.status {
			font-weight: 500;
			@include fs(13rem);
			color: $gray;
			line-height: 1.3; }
		&:first-child {
			.icon {
				// &:after
 } }				/// display: none
		&.active {
			.icon {
				color: $white;
				background: $gray;
				border-color: $gray;
				&:after {
					background: $gray; } } } } }
.account-info {
	@include pos(rel,null);
	margin-bottom: 15px;
	// +media-breakpoint-down(md)
	// 	margin-bottom: 20px
	.info-detail {
		border: 1px solid darken($white,10%);
		background: darken($white,3%);
		@include bor-rad(0);
		padding: 15px 20px;
		@include fs(14rem);
		line-height: 1;
		.group {
			margin-bottom: 10px;
			@include flexbox;
			flex-wrap: wrap;
			&:last-child {
				margin-bottom: 0; }
			.label {
				width: 150px;
				cursor: pointer;
				@include media-breakpoint-down(xs) {
					width: 100%;
					color: darken($white,40%); } }
			.detail {
				width: calc(100% - 150px);
				font-weight: 500;
				@include media-breakpoint-down(xs) {
					width: 100%;
					font-weight: 500; }
				&.point {
					color: $red;
					font-weight: 500; } }
			&.money {
				.label {
					width: auto;
					float: left;
					margin-right: 10px; }
				.detail {
					width: auto;
					float: left; } } } } }
.account-form {
	width: 50%;
	@include media-breakpoint-down(lg) {
		width: 60%; }
	@include media-breakpoint-down(md) {
		width: 70%; }
	@include media-breakpoint-down(xs) {
		width: 100%; }
	.form-group {
		margin-bottom: 15px;
		label {
			text-transform: uppercase;
			@include fs(13rem);
			color: darken($white,60%);
			margin-bottom: 5px; }
		input, select {
			width: 100%;
			border: 1px solid darken($white,15%);
			padding: 10px 15px;
			font-size: 0.875rem;
			-webkit-transition: all, 0.3s;
			@include transition(all, 0.3s);
			@include bor-rad(0);
			&:hover, &:active, &:focus {
				border-color: darken($white,40%); } } }
	.gender-select {
		margin-bottom: 0;
		ul {
			padding: 10px 0 0;
			&:after {
				display: table;
				content: '';
				width: 100%;
				clear: both; }
			li {
				width: 50%;
				float: left; } }
		input[type="radio"] {
			+ label {
				// font-weight: 600
				color: $main;
				text-transform: none;
				@include fs(14rem);
				@include pos(rel,null);
				@include transition(all,0.5s);
				&:hover {
					color: $black; } } } }
	.birthday {
		ul {
			margin: 0 -10px;
			li {
				width: 33.33%;
				float: left;
				padding: 0 10px;
				@include media-breakpoint-down(xs) {
					select {
						@include fs(14rem);
						padding: 10px 10px; } } } } }
	.check-group {
		margin-bottom: 15px; }
	.changepw-form {
		display: none; }
	input[type='submit'] {
		color: $white;
		background: $black;
		padding: 0 20px;
		border: none;
		@include fs(14rem);
		line-height: 36px;
		text-transform: uppercase;
		margin: 5px 0 0px;
		@include transition(all,0.3s);
		@include bor-rad(0);
		&:hover {
			background: lighten($gray,10%); } } }

.coin-info {
	@extend .account-info;
	height: 100%;
	margin-bottom: 30px;
	@include pos(rel,null);
	padding-top: 47px;
	@include media-breakpoint-down(md) {
		padding-top: 0; }
	.info-detail {
		.detail {
			@include media-breakpoint-up(md) {
				line-height: 38px; }
			strong {
				color: $gray; } } } }

.coin-page {
	.notification {
		color: $gray;
		margin-bottom: 10px; }
	.coin-amount {
		border: 1px solid darken($white,10%);
		@include bor-rad(0);
		padding: 10px 15px;
		margin-bottom: 10px;
		strong {
			color: $gray; } }
	.coin-history {
		padding-top: 20px;
		margin-bottom: 20px; } }

.cart-history {
	margin-bottom: 10px;
	@include pos(rel,null);
	article {
		margin-bottom: 15px; }
	.module-title {
		margin-bottom: 20px; }
	.content {
		margin-bottom: 20px;
		p {
			margin-bottom: 5px; } }
	.product-select {
		margin-bottom: 15px;
		select {
			@include bor-rad(0);
			padding: 10px 15px;
			border: 1px solid darken($white,10%); } }
	.table-responsive {
		margin-bottom: 20px; }
	.total {
		font-weight: 700;
		color: $gray; }
	.totalmoney {
		padding-top: 20px;
		strong {
			color: $gray;
			@include fs(24rem); }
		.payment-des {
			@include fs(14rem);
			font-style: italic;
			color: darken($white,40%); } }
	.total-amount {
		float: right;
		width: 300px;
		margin-bottom: 20px;
		.group {
			@include flexbox; }
		.label {
			width: 150px;
			float: left;
			text-align: right;
			padding-right: 10px;
			@include fs(13rem); }
		.value {
			@include fs(14rem);
			width: 150px;
			float: left;
			text-align: left;
			font-weight: 600;
			padding-left: 10px;
			color: $red; } }
	.btn-back {
		clear: both;
		margin-bottom: 0px;
		&:hover {
			background: $lblack;
			color: $white; } } }

.address-info {
	@include pos(rel,null);
	.address-list {
		margin: 0 -10px;
		display: flex;
		flex-wrap: wrap; }
	.address-col {
		width: 50%;
		padding: 0 10px;
		margin-bottom: 10px;
		@include media-breakpoint-down(sm) {
			width: 100%; }
		.address {
			border: 1px solid darken($white,10%);
			padding: 15px;
			background: $white;
			@include bor-rad(0);
			@include pos(rel,null);
			height: 100%;
			&.default {
				border: 1px dashed lighten($red,30%);
				background: lighten($red,65%); }
			.type {
				@include pos(abs, 5px 10px null null);
				text-transform: uppercase;
				@include fs(13rem);
				color: $red;
				font-weight: 500; }
			.name {
				@include fs(15rem);
				// font-family: 'open sans condensed'
				font-weight: 500;
				text-transform: uppercase;
 }				// margin-bottom: 5px
			.addr-detail {
				@include fs(13rem);
				color: darken($white,60%);
				margin-bottom: 10px; }
			.btn-editaddr {
				display: inline-block;
				background: $black;
				color: $white;
				padding: 7px 20px;
				@include fs(13rem);
				@include bor-rad(0);
				&:after {
					content: '\f040';
					font-family: 'fontawesome';
					margin-left: 5px; }
				&:hover {
					color: $white;
					background: $red; } } } }
	.address-form {
		width: 50%;
		@include media-breakpoint-down(lg) {
			width: 60%; }
		@include media-breakpoint-down(md) {
			width: 70%; }
		@include media-breakpoint-down(xs) {
			width: 100%; }
		.form-group {
			margin-bottom: 15px;
			label {
				text-transform: uppercase;
				@include fs(13rem);
				color: darken($white,60%);
				margin-bottom: 5px; }
			input, select, textarea {
				width: 100%;
				border: 1px solid darken($white,15%);
				padding: 10px 15px;
				@include bor-rad(0);
				font-size: 0.875rem;
				-webkit-transition: all, 0.3s;
				transition: all, 0.3s;
				&:hover, &:active, &:focus {
					border-color: darken($white,40%); } } }
		input[type="submit"] {
			color: $white;
			background: $gray;
			padding: 0 20px;
			border: none;
			@include fs(14rem);
			line-height: 36px;
			text-transform: uppercase;
			margin: 5px 0 40px;
			@include bor-rad(0);
			@include transition(all,0.5s);
			&:hover {
				background: darken($gray,10%); } }
		// .check-group
		// 	input[type='checkbox']
		// 		display: none
		// 		+ label
		// 			color: darken($white,60%)
		// 			font-weight: 300
		// 			padding-left: 30px
		// 			+fs(14rem)
		// 			+pos(rel,null)
		// 			+transition(all,0.5s)
		// 			text-transform: none
		// 			&:hover
		// 				color: $black
		// 			&:before
		// 				content: ''
		// 				display: block
		// 				width: 16px
		// 				height: 16px
		// 				border: 1px solid darken($white,40%)
		// 				opacity: 0.5
		// 				+pos(abs, 1px null null 0)
		// 				+transition(all,0.5s)
		// 			&:after
		// 				content: '\f00c'
		// 				font-family: 'fontawesome'
		// 				display: block
		// 				line-height: 14px
		// 				+fs(16rem)
		// 				color: $gray
		// 				opacity: 0
		// 				+pos(abs, 2px null null 1px)
		// 				+transition(all,0.5s)
		// 		&:checked
		// 			+ label
		// 				&:before, &:after
 } }		// 					opacity: 1

.wishlist {
	.product-list {
		border-top: 1px solid darken($white,10%);
		border-left: 1px solid darken($white,10%);
		.row {
			margin: 0px 0px; }
		.col-6 {
			padding: 0px 0px; }
		.product-col {
			border-right: 1px solid darken($white,10%);
			border-bottom: 1px solid darken($white,10%); } }
	// .wishlist-product
	// 	margin-bottom: 20px
	// 	.product
	// 		padding: 20px 0
	// 		border-bottom: 1px solid darken($white,10%)
	// 		figure
	// 			.product-img
	// 				display: block
	// 				width: 130px
	// 				height: 130px
	// 				padding: 10px
	// 				text-align: center
	// 				border: 1px solid darken($white,20%)
	// 				float: left
	// 				+media-breakpoint-down(xs)
	// 					width: 100%
	// 					height: auto
	// 					margin-bottom: 15px
	// 			figcaption
	// 				display: block
	// 				width: calc(100% - 130px)
	// 				float: left
	// 				padding: 15px 0px 15px 20px
	// 				+media-breakpoint-down(sm)
	// 					padding: 0 0 0 20px
	// 				+media-breakpoint-down(xs)
	// 					padding-left: 0
	// 					width: 100%
	// 				.product-info
	// 					float: left
	// 					+media-breakpoint-down(sm)
	// 						width: 100%
	// 						float: none
	// 				.product-name
	// 					display: inline-block
	// 					+fs(13rem)
	// 					text-transform: uppercase
	// 					// border-left: 3px solid $gray
	// 					// padding-left: 10px
	// 					margin-bottom: 5px
	// 					+media-breakpoint-down(xs)
	// 						display: block
	// 					a
	// 						color: $gray
	// 						+fs(20rem)
	// 						font-weight: 500
	// 				.size
	// 					color: darken($white,60%)
	// 					+media-breakpoint-down(xs)
	// 						display: block
	// 					&:before
	// 						content: '|'
	// 						margin: 0 10px
	// 						+media-breakpoint-down(xs)
	// 							display: none
	// 				.product-category
	// 					+fs(13rem)
	// 					text-transform: uppercase
	// 					color: darken($white,40%)
	// 					margin-bottom: 17px
	// 					// width: 100%
	// 					+media-breakpoint-down(sm)
	// 						margin-bottom: 5px
	// 				.product-price
	// 					margin-bottom: 5px
	// 					+media-breakpoint-down(xs)
	// 						margin-bottom: 15px
	// 					.old
	// 						display: inline-block
	// 						+fs(14rem)
	// 						color: darken($white,40%)
	// 						text-decoration: line-through
	// 						font-style: italic
	// 					.new
	// 						display: inline-block
	// 						+fs(18rem)
	// 						font-weight: 700
	// 						margin-left: 5px
	// 						color: $gray
	// 						+media-breakpoint-down(sm)
	// 							// +fs(24rem)
	// 				.button
	// 					float: right
	// 					+media-breakpoint-down(sm)
	// 						width: 100%
	// 						float: none
	// 				.btn-addtocart
	// 					display: inline-block
	// 					padding: 7px 20px
	// 					color: $white
	// 					+fs(13rem)
	// 					background: $gray
	// 					text-transform: uppercase
	// 					+transition(all,0.5s)
	// 					+media-breakpoint-down(xs)
	// 						width: calc(100% - 42px)
	// 					&:before
	// 						content: '\f07a'
	// 						font-family: 'fontawesome'
	// 						margin-right: 5px
	// 					&:hover
	// 						background: darken($gray,10%)
	// 				.btn-removecart
	// 					display: inline-block
	// 					margin-left: 5px
	// 					width: 33px
	// 					height: 33px
	// 					background: darken($white,10%)
	// 					+transition(all,0.5s)
	// 					&:hover
	// 						background: darken($white,20%)
 }	// 						color: $gray
.cart-info {
	border: 1px solid darken($white,20%);
	@include bor-rad(0);
	height: 100%;
	.title {
		font-weight: 500;
		text-transform: uppercase;
		color: $gray;
		padding: 10px;
		border-bottom: 1px solid darken($white,10%); }
	.cart-detail {
		padding: 10px;
		.form-group {
			@include flexbox;
			flex-wrap: wrap;
			@include fs(13rem);
			&:last-child {
				margin-bottom: 0; }
			.label {
				width: 150px;
				font-weight: 500; }
			.value {
				width: calc(100% - 150px); } } }
	.alert-success, .alert-warning, .alert-primary, .alert-info {
		background: none;
		font-weight: 600;
		filter: brightness(150%);
 } }		// text-transform: uppercase

.shipping-step {
	@include flexbox;
	margin-bottom: 20px;
	.step {
		width: 33.33%;
		@include pos(rel,null);
		@include flexbox;
		flex-wrap: wrap;
		justify-content: center;
		&:before {
			content: '';
			display: block;
			width: 100%;
			height: 3px;
			background: darken($white,20%);
			@include pos(abs, 40px 50% null null);
			@include transform(translateY(-1px)); }
		&:first-child {
			&:before {
				display: none; } }
		.icon {
			@include flexbox;
			width: 80px;
			height: 80px;
			@include bor-rad(50%);
			justify-content: center;
			align-items: center;
			border: 3px solid darken($white,20%);
			@include pos(rel,null);
			z-index: 1;
			background: $white;
			img {
				max-width: 60%;
				max-height: 60%; } }
		.name {
			width: 100%;
			text-align: center;
			padding: 10px 0;
			font-weight: 500;
			color: darken($white,60%); }
		&.active {
			&:before {
				background: $red; }
			.icon {
				border-color: $red; }
			.name {
				color: $red; } } } }

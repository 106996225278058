.banner-wrap {
	.banner {
		&.brand-banner {
			.slick-dots {
				opacity: 0; } }
		.slick-dots {
			@include pos(abs, null null 20px 50%);
			@include transform(translateX(-50%));
			z-index: 5;
			text-align: right;
			max-width: 1170px;
			width: 100%;
			padding: 0px 15px;
			@include media-breakpoint-down(lg) {
				max-width: 960px; }
			@include media-breakpoint-down(md) {
				max-width: 720px; }
			@include media-breakpoint-down(sm) {
				max-width: 540px; }
			@include media-breakpoint-down(xs) {
				max-width: 100%; }
			li {
				display: inline-block;
				width: 14px;
				height: 14px;
				@include bor-rad(50px);
				background: $white;
				cursor: pointer;
				@include transition(all,0.5s);
				&.slick-active {
					background: $red; }
				+ li {
					margin-left: 10px; }
				button {
					display: none; } } }
		.slick-prev,.slick-next {
			@include pos(abs, 50% null null null);
			@include transform(translateY(-50%));
			z-index: 5;
			@include fs(0);
			color: $white;
			opacity: 0;
			text-shadow: 0 3px 10px color-rgba($black,0.5);
			&:before {
				content: '';
				@include fs(36rem); } }
		&:hover {
			.slick-prev, .slick-next {
				opacity: 1; } }
		.slick-prev {
			left: 30px;
			&:before {
				content: '\f104'; } }
		.slick-next {
			right: 30px;
			&:before {
				content: '\f105'; } }
		.banner-img {
			img {
				width: 100%; } }
		.item {
			@include pos(rel,null);
			overflow: hidden;
			.banner-caption {
				@include pos(abs, null 0 150px 0);
				z-index: 5;
				text-align: center;
				.animated {
					animation-duration: 1s; }
				.logo {
					margin-bottom: 20px;
					text-align: center;
					opacity: 0;
					@include transform(translateX(-100%));
					@include transition(all,1s);
					// transition-delay: 1s
					img {
						display: initial; } }
				.caption {
					@include fs(16rem);
					font-weight: 300;
					color: $white;
					text-transform: uppercase;
					margin-bottom: 30px;
					opacity: 0;
					@include transform(translateX(100%));
					@include transition(all,1s);
 }					// transition-delay: 1.3s
				.link {
					display: inline-block;
					line-height: 40px;
					background: $red;
					text-transform: uppercase;
					color: $white;
					@include fs(14rem);
					font-weight: 300;
					padding: 0px 40px;
					@include bor-rad(5px);
					opacity: 0;
					@include transform(translateY(100%));
					@include transition(all,1s);
					// transition-delay: 1.6s
					&:hover {
						background: darken($red,10%); } } }
			&.slick-current {
				.banner-caption {
					.logo {
						opacity: 1;
						@include transform(translateX(0)); }
					.caption {
						opacity: 1;
						@include transform(translateX(0)); }
					.link {
						opacity: 1;
						@include transform(translateY(0)); } } } } } }


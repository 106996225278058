.page-title {
	@include fs(20rem);
	text-transform: uppercase;
	font-weight: 500;
	margin-bottom: 20px;
	line-height: 1.5;
	// border-left: 4px solid $red
	// padding-left: 10px
	&:after {
		content: '';
		display: block;
		width: 120px;
		height: 1px;
		background: $red;
		margin-top: 5px; }
	@include media-breakpoint-down(md) {
		@include fs(18rem); }
	@include media-breakpoint-down(xs) {
		@include fs(16rem); } }
.hot-news {
	background: #222;
	padding: 10px 0;
	.page-title {
		color: $white; }
	.hotnews-list {
		margin-bottom: 40px;
		article {
			// &:nth-child(2)
			// 	.news-col
			// 		figure
			// 			.news-img
			// 				&:before
			// 					background: linear-gradient(135deg,color-rgba($lpink,0.1),$pink)
			// &:nth-child(3)
			// 	.news-col
			// 		figure
			// 			.news-img
			// 				&:before
			// 					background: linear-gradient(135deg,color-rgba($lgreen,0.1),$green)
			// &:nth-child(4)
			// 	.news-col
			// 		figure
			// 			.news-img
			// 				&:before
 }			// 					background: linear-gradient(135deg,color-rgba($lpurple,0.1),$purple)
		.news-col {
			figure {
				@include pos(rel,null);
				.news-img {
					display: block;
					@include pos(rel,null);
					overflow: hidden;
					&:before {
						content: '';
						@include pos(abs, 0 null 0 0);
						right: 0;
						z-index: 5;
						background: linear-gradient(135deg,color-rgba($black,0),color-rgba($black,0.5));
						// filter: brightness(80%)
						opacity: 0.8; }
					img {
						width: 100%;
						height: 200px;
						object-fit: cover;
						@include transition(all,0.3s); }
					&:hover {
						// img
 } }						/// +transform(scale3d(1.1,1.1,1))
				figcaption {
					@include pos(abs, null 10px 10px 10px);
					z-index: 10;
					text-shadow: 0 3px 20px color-rgba($black,0.3);
					.news-name {
						@include fs(14rem);
						font-weight: 500;
						line-height: 1.5;
						// margin-bottom: 5px
						// text-transform: uppercase
						a {
							color: $white; } }
					time {
						@include fs(12rem);
						color: $white;
						// font-weight: 500
						opacity: 0.8; } } }
			&.big {
				figure {
					.news-img {
						img {
							height: 400px; }
						// &:before
 }						// 	background: linear-gradient(135deg,color-rgba($lred,0.1),$red)
					figcaption {
						.news-category {
							display: inline-block;
							@include fs(11rem);
							text-transform: uppercase;
							color: $white;
							background: $red;
							line-height: 20px;
							padding: 0px 5px;
							@include bor-rad(3px); }
						.news-name {
							@include fs(24rem);
							font-weight: 600; } } } } } } }
.lastest-news {
	margin: 40px 0;
	.post-list {
		padding: 10px;
		border: 1px solid darken($white,10%); }
	.post {
		padding: 7px;
		border-bottom: 1px dashed darken($white,10%);
		&.big {
			padding: 0;
			figure {
				@include pos(rel,null);
				.post-img {
					filter: brightness(70%); }
				figcaption {
					@include pos(abs, null 15px 15px 15px);
					z-index: 5;
					.post-name {
						@include fs(15rem);
						a {
							color: $white; } }
					time {
						color: $white;
						opacity: 0.7; } } } }
		figure {
			@include flexbox;
			.post-img {
				display: block; }
			figcaption {
				.post-name {
					@include fs(13rem);
					line-height: 1.3;
					font-weight: 500;
					a {
						color: $black;
						&:hover {
							color: $red; } } }
				time {
					@include fs(11rem);
					color: darken($white,40%);
					&:before {
						content: '\f017';
						margin-right: 5px; } } } } } }
.news-page {
	margin: 30px 0;
	.news-list {
		.col-12 {
			margin-bottom: 20px; }
		.news-col {
			figure {
				.news-img {
					display: block; }
				figcaption {
					padding: 10px 0;
					.news-name {
						@include fs(16rem);
						line-height: 1.5;
						font-weight: 500;
						a {
							color: $black; } }
					time {
						@include fs(12rem);
						color: darken($white,40%);
						display: block;
						margin-bottom: 10px;
						&:before {
							content: '\f017';
							margin-right: 5px; } }
					.news-des {
						@include fs(14rem);
						color: darken($white,60%); } }
				&:hover {
					figcaption {
						.news-name {
							a {
								color: $red; } } } } } } }
	.btn-wrap {
		@include flexbox;
		justify-content: center;
		.btn-viewmore {
			display: block;
			line-height: 36px;
			border: 1px solid $black;
			text-transform: uppercase;
			@include fs(13rem);
			padding: 0px 30px;
			color: $black;
			&:hover {
				color: $white;
				background: $black; } } }
	.content {
		+ .share {
			margin-top: 20px; } }
	.share {
		margin-bottom: 30px; } }
.news-info {
	margin-top: -10px;
	margin-bottom: 20px;
	time {
		@include fs(13rem);
		color: darken($white,40%);
		&:before {
			content: '\f133';
			margin-right: 5px; } } }
.other-news {
	.post-list {
		border-top: 1px dashed darken($white,10%);
		.post {
			display: block;
			padding: 10px 0;
			@include fs(13rem);
			color: $main;
			border-bottom: 1px dashed darken($white,10%);
			&:hover {
				color: $red; } } } }

@keyframes prnew {
	0% {
		background: $orange; }
	50% {
		background: $yellow; }
	100% {
		background: $orange; } }
@keyframes prsale {
	0% {
		background: $dred; }
	50% {
		background: $red; }
	100% {
		background: $dred; } }
.col-left {
	width: 280px;
	padding: 0px 15px;
	// margin-top: 20px
	@include media-breakpoint-down(lg) {
		width: 230px; }
	@include media-breakpoint-down(md) {
		width: 0;
		margin: 0; } }
.col-main {
	width: calc(100% - 280px);
	padding: 0px 15px;
	// border-left: 1px solid darken($white,10%)
	// margin-top: 20px
	@include media-breakpoint-down(lg) {
		width: calc(100% - 230px); }
	@include media-breakpoint-down(md) {
		width: 100%;
		border-left: none; } }

.bannerads {
	margin-bottom: 20px;
	overflow: hidden;
	@include media-breakpoint-down(sm) {
		margin-bottom: 10px; }
	.slick-prev,.slick-next {
		@include pos(abs,50% null null null);
		@include transform(translateY(-50%));
		@include fs(0);
		z-index: 5;
		color: $white;
		opacity: 0;
		&:before {
			content: '';
			@include fs(32rem); } }
	&:hover {
		.slick-prev,.slick-next {
			opacity: 1; } }
	.slick-prev {
		left: 15px;
		&:before {
			content: '\f104'; } }
	.slick-next {
		right: 15px;
		&:before {
			content: '\f105'; } }
	.slick-dots {
		@include pos(abs, null 30px 10px null);
		opacity: 0;
		li {
			display: inline-block;
			width: 10px;
			height: 10px;
			@include bor-rad(50px);
			background: $white;
			cursor: pointer;
			@include transition(all,0.5s);
			&.slick-active {
				background: $red; }
			+ li {
				margin-left: 8px; }
			button {
				display: none; } } } }

.category-info {
	// background: darken($white,5%)
	border-bottom: 1px solid $main;
	// +bor-rad(5px)
	padding: 10px 0px;
	margin-bottom: 30px;
	.cate-name {
		font-weight: 500;
		text-transform: uppercase;
		@include fs(18rem);
		float: left;
		margin-bottom: 5px; }
	.amount {
		float: left;
		clear: left;
		color: darken($white,40%);
		@include fs(12rem); }
	.product-sort {
		float: right;
		padding-top: 4px;
		select {
			border: 1px solid darken($white,10%);
			// +bor-rad(3px)
			padding: 5px 10px;
			color: darken($white,60%); } } }
.product-list {
	&.product-list2 {
		.row {
			margin: 0px -5px; }
		.col-custom {
			padding: 0px 5px;
			@include media-breakpoint-up(xl) {
				@include flex(0 0 20%); } } }
	.product-col {
		background: $white;
		figure {
			padding: 10px;
			@include pos(rel,null);
			.promotion {
				@include pos(abs, 10px null null 10px);
				z-index: 5;
				img {
					width: 25%; }
				&.new {}
				&.sale {}
				&.hot {} }
			.img-wrap {
				@include pos(rel,null);
				.product-img {
					// display: block
					// padding: 5px
					@include flexbox;
					justify-content: center;
					align-items: center;
					padding: 10px 0;
					// height: 200px
					img {
						max-width: 90%;
						max-height: 90%;
						@include transition(all,0.3s); } } }
			figcaption {
				text-align: center;
				padding: 0px 10px;
				@include pos(rel,null);
				.product-brand {
					@include fs(11rem);
					text-transform: uppercase;
					font-weight: 300;
					color: darken($white,40%);
					line-height: 1.5;
					@include media-breakpoint-down(md) {
						@include fs(10rem); } }
				.product-name {
					@include fs(15rem);
					text-transform: uppercase;
					line-height: 1.5;
					@include media-breakpoint-down(md) {
						@include fs(13rem); }
					// font-weight: 500
					a {
						color: $main; } }
				.product-status {
					@include fs(12rem);
					font-weight: 300;
					color: darken($white,40%);
					line-height: 1.5;
					@include media-breakpoint-down(md) {
						@include fs(10rem); } }
				.product-view {
					@include fs(12rem);
					font-weight: 300;
					color: darken($white,40%);
					line-height: 1.5;
					margin-bottom: 10px;
					@include media-breakpoint-down(md) {
						@include fs(10rem); } }
				.product-price {
					border-top: 1px solid color-rgba($black,0.1);
					padding: 5px 0;
					@include flexbox;
					flex-wrap: wrap;
					justify-content: center;
					.new {
						@include fs(13rem);
						font-weight: 500;
						color: $red;
						padding: 0px 3px; }
					.old {
						@include fs(11rem);
						text-decoration: line-through;
						color: darken($white,40%);
						margin-top: 1px;
						padding: 0px 3px; } }
				.btn-productwrap {
					display: none; }
				.btn-view {
					@include pos(abs, 50% null null 50%);
					@include transform(translateX(-50%));
					border: 1px solid $black;
					background: $black;
					color: $white;
					text-transform: uppercase;
					@include fs(14rem);
					padding: 0px 20px;
					line-height: 34px;
					margin-top: -17px;
					white-space: nowrap;
					opacity: 0;
					visibility: 0;
					display: none;
					&:hover {
						background: $red;
						border-color: $red;
						color: $white; } } }
			&:hover {
				.img-wrap {
					.product-img {
						img {
							// opacity: 0.5
							@include transform(scale3d(1.2,1.2,1)); } } }
				figcaption {
					.btn-view {
						opacity: 1; } } } } } }
.product-page {
	.product-list {
		border-top: 1px solid darken($white,10%);
		border-left: 1px solid darken($white,10%);
		.row {
			margin: 0px 0px; }
		.col-6 {
			padding: 0px 0px; }
		.product-col {
			border-right: 1px solid darken($white,10%);
			border-bottom: 1px solid darken($white,10%); } }

	.slidewrap {
		@include pos(rel,null);
		@include flexbox;
		margin-bottom: 30px;
		@include media-breakpoint-down(xs) {
			margin-bottom: 20px; }
		.product-name {
			@include fs(15rem);
			font-weight: 700;
			color: $orange;
			background: lighten($orange,65%);
			padding: 10px;
			margin: 10px 0;
			@include bor-rad(5px);
			width: 100%; }
		.promotion {
			color: $white;
			@include bor-rad(3px);
			@include fs(11rem);
			font-weight: 700;
			text-transform: uppercase;
			line-height: 1;
			padding: 3px 5px;
			margin-bottom: -17px;
			@include pos(rel,null);
			z-index: 5;
			&.new {
				background: $orange; }
			&.sale {
				background: $dred;
				animation-name: prsale;
				animation-duration: 1s;
				animation-iteration-count: infinite; } }
		.product-slide {
			width: calc(100% - 80px);
			margin-left: 80px;
			@include media-breakpoint-down(md) {
				width: calc(100% - 64px);
				margin-left: 64px; }
			@include media-breakpoint-down(xs) {
				width: 100%;
				margin-left: 0; }
			.item {
				.fancybox {
					@include flexbox;
					width: 100%;
					height: 400px;
					justify-content: center;
					align-items: center;
					@include media-breakpoint-down(md) {
						height: 350px; }
					@include media-breakpoint-down(xs) {
						height: 300px; }
					img {
						max-width: 100%;
						max-height: 100%;
						@include media-breakpoint-down(md) {
							max-width: 100%;
							max-height: 100%; }
						@include media-breakpoint-down(xs) {
							max-width: 80%;
							max-height: 80%; } } } } }
		.product-nav {
			@include pos(abs, 0 null null 0);
			width: 64px;
			@include media-breakpoint-down(md) {
				width: 64px; }
			@include media-breakpoint-down(xs) {
				@include pos(rel,null);
				width: 100%;
				padding: 0px 20px; }
			.slick-prev, .slick-next {
				@include fs(0);
				display: block;
				width: 100%;
				text-align: center;
				line-height: 1;
				color: darken($white,40%);
				@include media-breakpoint-down(xs) {
					@include pos(abs, 50% null null null);
					@include transform(translateY(-50%));
					z-index: 5;
					width: unset; }
				&:hover {
					color: $main; }
				&:before {
					content: '';
					@include fs(28rem); } }
			.slick-prev {
				@include media-breakpoint-down(xs) {
					left: 0; }
				&:before {
					content: '\f106';
					@include media-breakpoint-down(xs) {
						content: '\f104'; } } }
			.slick-next {
				@include media-breakpoint-down(xs) {
					right: 0; }
				&:before {
					content: '\f107';
					@include media-breakpoint-down(xs) {
						content: '\f105'; } } }
			.item {
				padding: 5px 0;
				@include media-breakpoint-down(xs) {
					padding: 0; }
				.img {
					width: 60px;
					height: 60px;
					@include flexbox;
					justify-content: center;
					align-items: center;
					border: 2px solid transparent;
					cursor: pointer;
					overflow: hidden;
					@include transition(all,0.5s);
					@include media-breakpoint-down(md) {
						width: 60px;
						height: 60px; }
					@include media-breakpoint-down(xs) {
						width: 100%;
						height: 60px; }
					img {
						max-width: 80%;
						width: 80%;
						@include media-breakpoint-down(xs) {
							max-width: 70%; } } }
				&.slick-current {
					.img {
						border-color: darken($white,20%); } } } } }
	.product-info {
		@include pos(rel,null);
		margin-bottom: 30px;
		@include media-breakpoint-down(md) {
			margin-bottom: 10px; }
		.promotion {
			@include pos(abs, 3px null null -30px);
			@include transform(translateX(-100%));
			color: $white;
			z-index: 5;
			// +bor-rad(3px)
			// font-weight: 700
			padding: 3px 10px;
			@include fs(13rem);
			text-transform: uppercase;
			@include media-breakpoint-down(md) {
				@include pos(static,unset);
				display: inline-block;
				@include transform(translateX(0));
				margin-bottom: 5px;
				@include fs(12rem);
				padding: 2px 8px; }
			&.new {
				background: $blue; }
			&.sale {
				background: $red; } }
		.product-name {
			@include fs(24rem);
			font-weight: 500;
			color: $main;
			line-height: 1.3;
			margin-bottom: 5px;
			@include media-breakpoint-down(lg) {
				@include fs(22rem);
				margin-bottom: 0; } }
		.product-id {
			color: darken($white,40%);
			margin-bottom: 10px;
			a {
				color: $blue;
				&:hover {
					color: $red; } } }
		.product-category {
			// +fs(13rem)
			color: darken($white,40%);
			margin-bottom: 15px; }
		.product-price {
			@include flexbox;
			margin-bottom: 20px;
			.new {
				@include fs(28rem);
				font-weight: 500;
				color: $red;
				margin-right: 20px;
				@include media-breakpoint-down(lg) {
					@include fs(22rem); } }
			.old {
				@include fs(18rem);
				text-decoration: line-through;
				color: darken($white,20%);
				margin-top: 10px;
				@include media-breakpoint-down(lg) {
					@include fs(16rem);
					margin-top: 6px; } } }
		.product-overview {
			color: darken($white,60%);
			margin-bottom: 20px;
			ul {
				@include flexbox;
				flex-wrap: wrap;
				li {
					@include pos(rel,null);
					width: 50%;
					padding: 0px 10px;
					@include fs(13rem);
					&:before {
						content: '';
						display: block;
						width: 6px;
						height: 6px;
						@include bor-rad(50px);
						background: darken($white,60%);
						@include pos(abs, 7px null null 0); } } } }
		.product-color, .product-size {
			width: 100%;
			// margin-bottom: 10px
			@include media-breakpoint-down(md) {
				width: 50%; }
			@include media-breakpoint-down(xs) {
				width: 100%;
				margin-bottom: 0; }
			.label {
				@include fs(12rem);
				font-weight: 500;
				color: $main;
				text-transform: uppercase;
				margin-bottom: 5px;
				@include media-breakpoint-down(md) {
					@include fs(12rem);
					margin-bottom: 5px; } }
			.color {
				display: inline-block;
				margin-right: 5px;
				margin-bottom: 5px;
				a {
					display: block;
					width: 28px;
					height: 28px;
					@include bor-rad(50px);
					// opacity: 0.5
					border: 2px solid darken($white,20%);
					@include pos(rel,null);
					&:before {
						content: '';
						display: block;
						border: 3px solid $white;
						@include bor-rad(50px);
						@include pos(abs, 0 null 0 0);
						right: 0; }
					&:hover {
						border-color: darken($white,40%); }
					&.disable {
						opacity: 0.2;
						border-color: darken($white,20%); }
					&.active {
						opacity: 1;
						border-color: $black; } } } }
		.btn-sizechart {
			padding: 10px 20px;
			// +bor-rad(3px)
			font-weight: 600;
			text-transform: uppercase;
			@include fs(13rem);
			background: darken($white,10%);
			color: $main;
			display: inline-block;
			&:hover {
				background: $orange;
				color: $white; }
			.fa {
				@include fs(16rem);
				margin-right: 10px; } }
		.popup-sizechart {}
		.product-size {
			margin-bottom: 20px;
			.size {
				display: inline-block;
				margin-right: 5px;
				a {
					display: block;
					@include fs(12rem);
					font-weight: 600;
					padding: 3px 10px;
					border: 1px solid darken($white,40%);
					color: darken($white,40%);
					// +bor-rad(3px)
					&:hover {
						// border-color: $main
						color: $main; }
					&.disable {
						border-color: darken($white,10%);
						opacity: 0.5;
						color: darken($white,40%); }
					&.active {
						color: $black;
						font-weight: 700;
						border-color: $main; } } } }
		.input-number {
			vertical-align: top;
			margin-right: 10px;
			// margin-bottom: 20px
			@include media-breakpoint-down(xs) {
				margin-right: 5px; } }
		.btn-wrap {
			display: inline-block;
			vertical-align: top;
			margin-bottom: 15px;
			@include media-breakpoint-down(sm) {
				@include pos(fix, null 0 0 0);
				background: $white;
				padding: 10px 15px;
				@include flexbox;
				z-index: 900;
				margin: 0; }
			.btn-buy {
				display: inline-block;
				vertical-align: top;
				line-height: 40px;
				// +bor-rad(50px)
				background: $black;
				@include fs(15rem);
				text-transform: uppercase;
				font-weight: 500;
				color: $white;
				padding: 0px 50px;
				overflow: hidden;
				text-align: center;
				@include media-breakpoint-down(md);
				@include media-breakpoint-down(sm) {
					display: block;
					text-align: center;
					width: calc(100% - 45px); }
				@include media-breakpoint-down(xs) {
					@include fs(14rem);
					padding: 0px 10px;
					// order: 2
					// margin-left: 10px
 }					// width: calc(100% - 50px)
				&:before {
					// background: color-rgba($white,0.1)
					background: color-rgba($white,0.2); }
				&:hover {
 } }					// color: $orange
			.btn-wishlist {
				display: inline-block;
				width: 40px;
				height: 40px;
				line-height: 40px;
				text-align: center;
				color: $black;
				background: none;
				border: 1px solid $black;
				// +bor-rad(50px)
				margin-left: 10px;
				@include fs(18rem);
				@include media-breakpoint-down(md) {
					margin-left: 5px; }
				@include media-breakpoint-down(sm) {
					display: block; }
				&:hover {
					border-color: lighten($red,20%);
					background: lighten($red,20%);
					color: $white; }
				&.active {
					border-color: lighten($red,20%);
					background: lighten($red,20%);
					color: $white; } }
			.buy-support {
				@include fs(14rem);
				display: block;
				color: darken($white,60%);
				padding: 10px 0;
				@include media-breakpoint-down(sm) {
					display: none; }
				&:after {
					content: '\f105';
					margin-left: 5px; }
				&:hover {
					color: $red; } } }
		.btnview-wrap {
			margin: -10px 0 20px;
			a {
				display: inline-block;
				@include fs(13rem);
				font-weight: 500;
				border: 1px solid darken($white,10%);
				padding: 4px 15px;
				color: $main;
				&:hover {
					background: $black;
					color: $white;
					img {
						filter: invert(100%) brightness(200%); } }
				+ a {
					margin-left: 10px; }
				.img {
					display: inline-block;
					vertical-align: middle;
					width: 24px;
					height: 24px;
					margin-right: 10px;
					img {
						max-width: 24px;
						max-height: 24px; } } } }
		.product-brand, .product-gift, .product-support {
			padding: 10px;
			border: 1px solid darken($white,10%);
			margin-bottom: 20px;
			.title {
				text-align: center;
				@include pos(rel,null);
				&:after {
					content: '';
					display: block;
					width: 100%;
					height: 1px;
					background: color-rgba($black,0.1);
					@include pos(abs, 50% 0 null 0); }
				span {
					display: inline-block;
					background: $white;
					font-weight: 500;
					@include fs(13rem);
					text-transform: uppercase;
					@include pos(rel,null);
					z-index: 5;
					padding: 0px 10px; } }
			.logo {
				a {
					@include flexbox;
					justify-content: center;
					align-items: center;
					height: 100px; }
				img {
					max-width: 80%;
					max-height: 80%; } }
			ul {
				padding: 10px;
				li {
					@include fs(13rem);
					padding-left: 15px;
					@include pos(rel,null);
					&:before {
						content: '';
						display: block;
						width: 6px;
						height: 6px;
						@include bor-rad(50px);
						background: $red;
						@include pos(abs, 7px null null 0); }
					a {
						color: darken($white,40%);
						&:hover {
							color: $black; } } } } }
		.product-support {
			ul {
				padding: 10px 0;
				li {
					padding-left: 0;
					// color: darken($white,40%)
					&:before {
						display: none; }
					a {
						display: block;
						font-weight: 500;
						color: $red; } } } }
		.whychooseus {
			@include media-breakpoint-down(lg) {
				display: none; }
			.title {
				text-align: center;
				@include pos(rel,null);
				margin-bottom: 15px;
				&:after {
					content: '';
					display: block;
					width: 100%;
					height: 1px;
					background: color-rgba($black,0.1);
					@include pos(abs, 50% 0 null 0); }
				span {
					display: inline-block;
					background: $white;
					font-weight: 500;
					@include fs(13rem);
					text-transform: uppercase;
					@include pos(rel,null);
					z-index: 5;
					padding: 0px 10px; } }
			ul {
				li {
					@include flexbox;
					margin-bottom: 10px;
					.icon {
						width: 40px;
						height: 40px;
						@include flexbox;
						justify-content: center;
						align-items: center;
						overflow: hidden;
						img {
							max-width: 80%;
							max-height: 80%; } }
					.text {
						width: calc(100% - 40px);
						padding-left: 10px;
						// +flexbox
						// line-height: 1.3
						// align-items: center
						// align-content: center
						@include fs(13rem);
						a {
							display: inline-block;
							color: $blue;
							&:hover {
								color: $red; } } } } } }

		.shipping-notice {
			margin: 10px 0px 20px;
			@include fs(14rem);
			// font-weight: 500
			color: $red;
			font-style: italic;
			&:before {
				content: '"'; }
			&:after {
				content: '"'; } }
		.share {
			@include media-breakpoint-down(lg) {
				margin-bottom: 15px; } } }
	.product-description {
		margin-bottom: 30px;
		.tabs-list {
			text-align: center;
			background: darken($white,3%);
			// margin-bottom: 30px
			// border: 1px solid darken($white,10%)
			.tab-item {
				display: inline-block;
				a {
					display: block;
					color: darken($white,40%);
					line-height: 40px;
					padding: 0px 10px;
					@include fs(14rem);
					font-weight: 500;
					// font-weight: 500
					// text-transform: uppercase
					@include pos(rel,null);
					&:before {
						content: '';
						display: block;
						@include pos(abs, null 100% 0 0);
						height: 2px;
						background: $black;
						@include transition(all,0.3s); } }
				&:hover {
					a {
						color: $main;
						&:before {
							right: 0; } } }
				&.active, &.r-tabs-state-active {
					a {
						font-weight: 600;
						color: $black;
						&:before {
							right: 0; } } } } }
		.r-tabs-accordion-title {
			margin-top: 10px;
			a {
				display: block;
				background: darken($white,5%);
				color: $main;
				@include fs(14rem);
				font-weight: 500;
				padding: 10px 20px;
				@include pos(rel,null);
				&:after {
					content: '\f107';
					display: block;
					@include pos(abs, 10px 20px null null);
					@include transition(all,0.3s); } }
			&.r-tabs-state-active {
				a {
					background: darken($white,15%);
					color: $red;
					&:after {
						@include transform(scaleY(-1)); } } } }
		.tab-content {
			padding: 30px 0 0;
			@include media-breakpoint-down(md) {
				border: 1px solid darken($white,15%);
				padding: 15px;
				&.r-tabs-state-active {
					max-height: {} } } }
		.title {
			@include pos(rel,null);
			margin: 20px 0;
			&:after {
				content: '';
				display: block;
				width: 100%;
				height: 1px;
				background: color-rgba($black,0.1);
				@include pos(abs, 50% 0 null 0); }
			span {
				display: inline-block;
				@include fs(16rem);
				text-transform: uppercase;
				font-weight: 500;
				background: $white;
				padding-right: 15px;
				@include pos(rel,null);
				z-index: 5; } }
		table.product-table {
			border-left: 1px solid darken($white,15%);
			border-top: 1px solid darken($white,15%);
			min-width: 500px;
			margin-bottom: 15px;
			@include media-breakpoint-down(xs) {
				min-width: unset;
				width: 100%; }
			td {
				border-bottom: 1px solid darken($white,15%);
				border-right: 1px solid darken($white,15%);
				padding: 10px;
				color: $main;
				@include fs(14rem);
				&:first-child {
					background: darken($white,3%);
					font-weight: 500; } } }
		.video {
			@include media-breakpoint-down(xs) {
				iframe {
					height: 200px; } } }
		.feature-list {
			@include flexbox;
			flex-wrap: wrap;
			justify-content: center;
			margin: 30px 0;
			.module-title {
				width: 100%;
				text-align: center;
				font-weight: 600;
				margin-bottom: 20px; }
			.feature-col {
				width: 20%;
				height: 200px;
				margin-bottom: 40px;
				@include pos(rel,null);
				overflow: hidden;
				text-align: center;
				cursor: pointer;
				@include media-breakpoint-down(lg) {
					width: 25%; }
				@include media-breakpoint-down(md) {
					width: 33.33%; }
				@include media-breakpoint-down(sm) {
					width: 50%;
					height: auto;
					overflow: visible; }
				@include media-breakpoint-down(xs) {
					width: 100%;
					margin-bottom: 20px; }
				figure {
					.feature-icon {
						@include flexbox;
						justify-content: center;
						align-items: center;
						height: 70px;
						margin-bottom: 15px; }
					figcaption {
						.feature-name {
							font-weight: 700;
							color: $main; }
						.feature-des {
							@include fs(12rem);
							padding: 10px 15px;
							@include pos(abs, 140px 0 null 0);
							z-index: 5;
							color: darken($white,40%);
							border: 1px solid transparent;
							background: $white;
							@include media-breakpoint-down(sm) {
								@include pos(static,null);
								max-width: 80%;
								margin: 10px auto; }
							@include media-breakpoint-down(xs) {
								border-color: darken($white,40%); } } } }
				&:hover {
					overflow: visible;
					figure {
						figcaption {
							.feature-des {
								border: 1px solid darken($white,40%); } } } } } }
		// .flymenu-wrap
		// 	width: 280px
		// 	float: left
		// 	padding: 0px 15px
		// 	+media-breakpoint-down(lg)
		// 		width: 230px
		// 	+media-breakpoint-down(md)
		// 		+pos(fix, 150px 0 null null)
		// 		background: $orange
		// 		z-index: 50
		// 		+transform(translateX(100%))
		// 		+transition(all,0.5s)
		// 		&:hover
		// 			+transform(translateX(0%))
		// 		&:before
		// 			content: '\f00b'
		// 			font-family: 'fontawesome'
		// 			display: block
		// 			width: 36px
		// 			height: 36px
		// 			line-height: 36px
		// 			background: $orange
		// 			color: $white
		// 			text-align: center
		// 			+pos(abs, 0 null null 0)
		// 			+transform(translateX(-100%))
		// .fly-menu
		// 	border-right: 1px solid darken($white,10%)
		// 	+media-breakpoint-down(md)
		// 		border: none
		// 	ul
		// 		li
		// 			a
		// 				display: block
		// 				line-height: 40px
		// 				font-weight: 700
		// 				color: darken($white,40%)
		// 				text-transform: uppercase
		// 				+pos(rel,null)
		// 				+media-breakpoint-down(md)
		// 					line-height: 36px
		// 					padding: 0px 15px
		// 					+fs(13rem)
		// 					color: $white
		// 				&:after
		// 					content: ''
		// 					display: block
		// 					width: 2px
		// 					+pos(abs, 0 -1px 100% null)
		// 					background: $main
		// 					+transition(all,0.5s)
		// 					+media-breakpoint-down(md)
		// 						display: none
		// 			&:hover
		// 				a
		// 					color: darken($white,50%)
		// 					+media-breakpoint-down(md)
		// 						color: $white
		// 					&:after
		// 						bottom: 0
		// 			&.active
		// 				a
		// 					color: $main
		// 					+media-breakpoint-down(md)
		// 						color: $white
		// 						background: color-rgba($white,0.2)
		// 					&:after
		// 						bottom: 0
		// .main-desc
		// 	width: calc(100% - 280px)
		// 	float: right
		// 	padding: 0px 15px
		// 	padding-left: 30px
		// 	+media-breakpoint-down(lg)
		// 		width: calc(100% - 230px)
		// 	+media-breakpoint-down(md)
		// 		width: 100%
 } }		// 		padding: 0px 15px
.product-contain {
	margin-bottom: 30px;
	.small-title {
		margin-bottom: 30px; }
	.contain {
		figure {
			text-align: center;
			.contain-img {}
			figcaption {
				padding-top: 10px;
				.number {
					@include fs(24rem);
					color: $orange;
					font-weight: 600;
 }					// margin-bottom: 10px
				.name {
					@include fs(18rem); } } } } }

.small-title {
	@include fs(14rem);
	margin-bottom: 10px;
	color: $white;
	background: $black;
	padding: 10px 20px;
	text-transform: uppercase;
	// &:after
	// 	content: ''
	// 	display: block
	// 	width: 60px
	// 	height: 1px
	// 	background: $red
 }	/// margin-top: 5px
.product-service {
	margin-bottom: 30px;
	.content {
		background: darken($white,3%);
		padding: 15px;
		@include fs(14rem); } }
.product-other {
	margin-bottom: 30px;
	.product-list {
		border-top: 1px solid darken($white,10%);
		border-left: 1px solid darken($white,10%);
		.row {
			margin: 0px 0px; }
		.col-6 {
			padding: 0px 0px; }
		.product-col {
			border-right: 1px solid darken($white,10%);
			border-bottom: 1px solid darken($white,10%); } }
	.other-slide {
		@include pos(rel,null);
		&:after {
			content: '';
			@include pos(abs, 0 0 0 null);
			width: 1px;
			background: darken($white,10%); }
		.slick-prev, .slick-next {
			@include pos(abs, 50% null null null);
			@include transform(translateY(-50%));
			@include fs(0);
			text-align: center;
			@include bor-rad(50px);
			z-index: 10;
			color: darken($white,60%);
			opacity: 0;
			&:hover {
				color: $orange; }
			&.slick-disabled {
				opacity: 0; }
			&:before {
				content: '';
				font-family: 'fontawesome';
				@include fs(28rem); } }
		&:hover {
			.slick-prev, .slick-next {
				opacity: 1; } }
		.slick-prev {
			left: 10px;
			&:before {
				content: '\f104'; } }
		.slick-next {
			right: 10px;
			&:before {
				content: '\f105'; } } } }

.pages {
	// border-top: 1px solid darken($white,10%)
	margin: 30px 0 40px;
	background: darken($white,3%);
	justify-content: center;
	.pagination {
		padding: 0;
		justify-content: center;
		li {
			display: inline-block;
			a, span {
				display: block;
				width: 36px;
				height: 36px;
				line-height: 36px;
				text-align: center;
				// font-weight: 500
				color: darken($white,40%); }
			&:hover {
				a, span {
					color: $main; } }
			&.active {
				a, span {
					color: $main;
					background: color-rgba($black,0.1);
					font-weight: 700; }
 } } } }				// border: 1px solid darken($white,10%)
.share {
	.label {
		font-weight: 700;
		margin-right: 15px;
		display: inline-block;
		vertical-align: middle;
		margin-bottom: 0;
		text-transform: uppercase;
		@include fs(14rem); }
	a {
		@include fs(18rem);
		color: darken($white,60%);
		display: inline-block;
		vertical-align: middle;
		.fa {
			@include transition(all,0.5s); }
		+ a {
			margin-left: 15px; } }
	.fa-facebook {
		&:hover {
			color: $facebook; } }
	.fa-twitter {
		&:hover {
			color: $twitter; } }
	.fa-pinterest-p {
		&:hover {
			color: $pint; } }
	.fa-google-plus {
		&:hover {
			color: $google; } } }

.view360 {
	text-align: center;
	@include flexbox;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	.productimg {
		width: 100%;
		img {
			height: 500px; } }
	.view360img {
		width: 100%;
		margin-top: -150px; } }

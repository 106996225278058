body {
	padding-top: 166px;
	@include media-breakpoint-down(lg) {
		padding-top: 60px; } }
header {
	background: $white;
	box-shadow: 0 10px 20px -10px color-rgba($black,0.1);
	@include pos(fix, 0 0 null 0);
	z-index: 1000;
	@include media-breakpoint-down(lg) {
		background: $black; }
	.container {
		@include media-breakpoint-down(lg) {
			max-width: 100%;
			width: 100%; } }
	.headertop {
		background: $black; }
	.top-menu {
		float: left;
		ul {
			li {
				display: inline-block;
				+ li {
					margin-left: 15px; }
				a {
					display: block;
					color: $white;
					line-height: 40px;
					@include fs(13rem);
					&:hover {
						color: $red; }
 } } } }					// font-weight: 300
	.colright {
		float: right; }
	.login {
		float: left;
		text-transform: uppercase;
		@include media-breakpoint-down(lg) {
			float: none; }
		ul {
			li {
				display: inline-block;
				@include media-breakpoint-down(lg) {
					display: block; }
				+ li {
					margin-left: 30px;
					@include media-breakpoint-down(lg) {
						margin-left: 0; } }
				&:last-child {
					a {
						&:before {
							content: '\f13e';
							@include media-breakpoint-down(lg) {
								display: none; } } } }
				a {
					display: block;
					color: $white;
					line-height: 40px;
					@include fs(11rem);
					@include media-breakpoint-down(lg) {
						color: $main;
						padding: 8px 15px;
						line-height: 1.5; }
					&:before {
						content: '\f234';
						margin-right: 5px;
						line-height: 1;
						@include media-breakpoint-down(lg) {
							display: none; } }
					&:hover {
						color: $red;
						@include media-breakpoint-down(lg) {
							background: darken($white,5%); } }
 } } } }					// font-weight: 300
	.language {
		float: left;
		margin-left: 30px;
		text-transform: uppercase;
		@include media-breakpoint-down(lg) {
			float: none;
			margin-left: 0;
			@include pos(abs, null 0 0 0);
			padding: 10px;
			@include flexbox;
			text-transform: none;
			a {
				display: block;
				width: 50%;
				@include fs(14rem);
				// text-transform: uppercase
				color: $white;
				text-align: center;
				opacity: 0.5;
				+ a {
					border-left: 1px solid color-rgba($white,0.2); }
				&.active {
					opacity: 1; } } }
		.dropdown {
			.dropdown-toggle {
				@include flexbox;
				@include fs(11rem);
				color: $white;
				@include pos(rel,null);
				line-height: 40px;
				&:hover {
					color: $red; }
				.icon {
					width: 24px;
					@include flexbox;
					align-items: center;
					img {
						width: 100%;
						display: block; } }
				.text {
					width: calc(100% - 24px);
					padding-left: 5px;
					@include flexbox;
					align-items: center;
					align-content: center; }
				&:after {
					content: '\f107';
					border: none;
					width: unset;
					height: unset;
					display: inline-block;
					vertical-align: top;
					font-weight: normal; } }
			.dropdown-menu {
				max-width: unset;
				min-width: 300px;
				max-height: 80vh;
				overflow: auto;
				padding: 0;
				.group {
					.group-title {
						background: darken($white,3%);
						padding: 5px 10px;
						@include fs(11rem);
						font-weight: 600;
						color: darken($white,60%);
						text-transform: uppercase; }
					ul {
						@include flexbox;
						flex-wrap: wrap;
						li {
							width: 50%;
							padding: 5px;
							a {
								@include flexbox;
								@include fs(11rem);
								padding: 5px;
								color: darken($white,40%);
								.icon {
									width: 24px;
									@include flexbox;
									align-items: center;
									img {
										width: 100%;
										display: block; } }
								.text {
									padding-left: 5px;
									width: calc(100% - 24px);
									@include flexbox;
									align-items: center;
									align-content: center; } }
							&:hover {
								a {
									color: $main; } }
							&.active {
								a {
									background: lighten($red,45%);
									color: $black; } } } } } } } }
	.social {
		float: left;
		margin-left: 20px;
		padding: 6px 0;
		a {
			display: inline-block;
			width: 28px;
			height: 28px;
			line-height: 28px;
			text-align: center;
			@include bor-rad(50px);
			background: $main;
			color: $white;
			@include fs(14rem);
			+ a {
				margin-left: 10px; }
			&:hover {
				background: $white;
				.fa-facebook {
					color: $facebook; }
				.fa-twitter {
					color: $twitter; }
				.fa-youtube-play {
					color: $youtube; } } } }
	.headermain {
		border-bottom: 1px solid color-rgba($black,0.1); }
	.sitelogo {
		@include flexbox;
		height: 85px;
		align-items: center;
		float: left;
		@include media-breakpoint-down(lg) {
			height: 60px; }
		@include media-breakpoint-down(sm) {
			width: 35px;
			overflow: hidden; }
		a {
			display: block; }
		img {
			@include media-breakpoint-down(lg) {
				height: 30px; }
			@include media-breakpoint-down(sm) {
				max-width: unset; } } }
	.search {
		float: left;
		margin-left: 30px;
		height: 85px;
		@include flexbox;
		align-items: center;
		@include media-breakpoint-down(lg) {
			height: 60px; }
		@include media-breakpoint-down(sm) {
			margin-left: 10px;
			width: calc(100% - 155px); }
		.searchwrap {
			width: 100%; }
		.searchbox {
			@include flexbox;
			width: 400px;
			// overflow: hidden
			@include media-breakpoint-down(sm) {
				width: 100%; }
			input {
				background: darken($white,10%);
				width: calc(100% - 90px - 41px);
				padding: 0 20px;
				margin: 0;
				@include bor-rad(5px 0 0 5px);
				border: none;
				@include fs(13rem);
				@include media-breakpoint-down(lg) {
					background: $white;
					line-height: 34px;
					width: calc(100% - 34px - 34px); } }
			.btn-advance {
				display: block;
				@include fs(13rem);
				color: $white;
				background: $red;
				line-height: 40px;
				width: 90px;
				text-align: center;
				// padding: 0 10px
				margin: 0;
				cursor: pointer;
				@include media-breakpoint-down(lg) {
					line-height: 34px;
					height: 34px;
					width: 34px;
					@include fs(0);
					background: $white;
					color: $main;
					&:before {
						content: '\f013';
						@include fs(18rem);
						line-height: 34px; } }
				&:hover {
					background: darken($red,10%); } }
			.searchbutton {
				width: 40px;
				height: 40px;
				line-height: 40px;
				color: $white;
				@include fs(18rem);
				@include bor-rad(0 5px 5px 0);
				background: $red;
				border-left: 1px solid $white;
				@include media-breakpoint-down(lg) {
					width: 34px;
					height: 34px;
					@include fs(16rem);
					line-height: 34px;
					border-color: color-rgba($white,0.5); } } } }
	.login-dropdown {
		float: right;
		@include flexbox;
		justify-content: center;
		align-items: center;
		align-content: center;
		height: 60px;
		margin-left: 15px;
		.dropdown-toggle {
			color: $white;
			@include fs(20rem);
			&:after {
				display: none; } }
		.dropdown-menu {
			@include media-breakpoint-down(lg) {
				min-width: 120px; } } }
	.sitefeature {
		width: 280px;
		height: 85px;
		float: right;
		@include flexbox;
		align-items: center;
		.feature {
			width: 33.33%;
			text-align: center;
			.icon {
				@include fs(20rem);
				color: $red; }
			.name {
				@include fs(12rem);
				line-height: 1.3;
				color: darken($white,60%); } } }
	.headermenu {
		.container {
			@include media-breakpoint-up(xl) {
				@include pos(rel,null); } }
 }		// border-bottom: 1px solid color-rgba($black,0.1)
	.btn-showmenu {
		@include flexbox;
		float: left;
		height: 60px;
		@include fs(24rem);
		align-items: center;
		justify-content: center;
		margin-right: 15px;
		color: $white; }

	.menu {
		float: left;
		ul.menulink {
			> li {
				float: left;
				> a {
					display: block;
					color: $main;
					@include fs(14rem);
					@include bor-rad(5px 5px 0 0);
					font-weight: 500;
					line-height: 40px;
					padding: 0px 20px;
					text-transform: uppercase; }
				&.hasmega {
					.mega {
						@include pos(abs, null 15px null 15px);
						z-index: 1;
						background: $white;
						padding: 30px 40px;
						box-shadow: 0px 10px 30px color-rgba($black,0.3);
						display: none;
						.col-custom {
							flex: 0 0 23%;
							max-width: unset; }
						.col-custom2 {
							flex: 0 0 31%;
							max-width: unset; }
						.title {
							font-weight: 500;
							text-transform: uppercase;
							color: $red;
							margin-bottom: 10px; }
						ul.megalink {
							&.filter {
								max-height: 230px;
								overflow: auto;
								padding-right: 10px;
								&::-webkit-scrollbar-track {
									background-color: darken($white,10%); }
								&::-webkit-scrollbar {
									width: 4px;
									background-color: darken($white,10%); }
								&::-webkit-scrollbar-thumb {
									background-color: darken($white,40%); }
								> li {
									> a {
										@include fs(12rem);
										text-transform: none; } } }
							> li {
								border-bottom: 1px dashed darken($white,20%);
								@include pos(rel,null);
								> a {
									color: $main;
									display: block;
									@include fs(13rem);
									padding: 10px 0;
									text-transform: uppercase; }
								.mega-sub {
									@include flexbox;
									@include pos(abs, 0 null null 50%);
									background: $white;
									box-shadow: 0px 10px 30px color-rgba($black,0.3);
									min-width: 500px;
									padding: 20px 30px;
									z-index: 5;
									visibility: hidden;
									opacity: 0;
									@include transition(all,0.3s);
									.megasub-wrap {
										width: calc(100% - 300px);
										.title {
											@include fs(14rem);
											margin-bottom: 0; }
										> ul {
											> li {
												> a {
													@include fs(12rem);
													white-space: nowrap;
													// text-transform: uppercase
													color: $main; } } } }
									.megasub-img {
										margin-left: 30px;
										width: 270px;
										a {
											display: block;
											img {
												width: 100%; } } } }
								&:hover {
									> a {
										color: $red; }
									.mega-sub {
										visibility: visible;
										opacity: 1; } } } }
						.mega-sale {
							.title {
								@include fs(13rem);
								background: darken($white,10%);
								color: $main;
								text-align: center;
								display: block;
								width: 100%;
								padding: 7px 0;
								margin-bottom: 0; }
							.img {
								display: block;
								img {
									width: 100%; } } } } }
				&:hover {
					> a {
						color: $red; }
					.mega {
						display: block; } }
				&.active {
					> a {
						color: $white;
						background: $red; } } } } }
	.cart {
		float: right;
		@include media-breakpoint-down(lg) {
			padding: 10px 0; }
		.cart-toggle {
			color: $red;
			@include fs(18rem);
			line-height: 39px;
			@include media-breakpoint-down(lg) {
				@include pos(rel,null);
				width: 40px;
				text-align: center;
				line-height: 40px;
				color: $white;
				@include fs(20rem); }
			.fa {
				line-height: 1; }
			.cart-amount {
				display: inline;
				@include fs(13rem);
				margin-left: 5px;
				@include media-breakpoint-down(lg) {
					@include fs(0);
					@include pos(abs, 0 -5px null null);
					background: $red;
					@include bor-rad(50px);
					color: $white;
					display: block;
					white-space: nowrap;
					line-height: 1;
					padding: 2px 5px; }
				strong {
					font-weight: 500;
					@include media-breakpoint-down(lg) {
						@include fs(11rem); } } } } } }

.fullpage {
	@include media-breakpoint-down(lg) {
		// +transition(all,0.5s)
		// &.open
		// 	+pos(fix, 0 null 0 0)
		// 	right: 0
		// 	z-index: 1000
		// 	overflow: hidden
		// 	box-shadow: 0px 10px 70px color-rgba($black,0.1)
 } }		// 	+transform(scale3d(0.8,0.8,0.8))
.mobile-menu {
	@include pos(fix, 0 null 0 0);
	right: 0;
	z-index: 1100;
	padding: 20px 35px;
	background: linear-gradient(135deg, color-rgba($black,0.95), color-rgba(darken($red,20%),0.95));
	@include transform(translateX(-100%));
	@include transition(all,0.3s);
	&.open {
		@include transform(translateX(0)); }
	.btn-closemenu {
		@include pos(abs, 20px 20px null null);
		color: $white;
		@include fs(24rem);
		line-height: 1; }
	.social {
		@include flexbox;
		margin-bottom: 20px;
		margin-left: 0;
		float: none;
		a {
			display: block;
			color: $white;
			@include fs(18rem);
			line-height: 1;
			background: none;
			width: auto;
			height: auto;
			+ a {
				margin-left: 25px; } } }
	.menu-wrap {
		max-height: calc(100vh - 70px - 60px);
		ul.menulink {
			margin: 0px -15px;
			> li {
				> a {
					display: block;
					color: $white;
					padding: 10px 15px;
					@include fs(18rem);
					font-weight: 600; }
				&.active {
					> a {
						color: $red;
						font-weight: 700; } } } } } }

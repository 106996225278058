.journalEditor {
	.journalClose {}
	textarea, input {
		margin-bottom: 10px; }
	.journalInfo {
		input {
			width: calc((100% - 10px)/2);
			+ input {
				margin-left: 10px; } }
		.btnShare {} }
	.journalPlaceholder {} }

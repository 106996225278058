// CART HEADER

.cartheader {
	// background: $
	border-bottom: 2px solid $gray;
	.btn-headback {
		line-height: 30px;
		@include fs(18rem);
		color: darken($white,60%);
		float: left;
		padding: 15px 0;
		margin-right: 15px; }
	.sitelogo {
		@include flexbox;
		align-items: center;
		align-content: center;
		height: 70px;
		float: left;
		a {
			display: block;
			img {
				height: 50px;
 } } }				// filter: grayscale(100%) brightness(400%)
	.hotline {
		float: right;
		@include flexbox;
		height: 70px;
		align-items: center;
		align-content: center;
		// +bor-rad(50px)
		// background: color-rgba($black,0.2)
		@include media-breakpoint-down(xs) {
			display: none; }
		.fa {
			display: block;
			width: 40px;
			height: 40px;
			line-height: 40px;
			text-align: center;
			@include fs(36rem);
			// +bor-rad(50px)
			float: left;
			background: $white;
			color: $gray;
			margin-right: 5px; }
		a {
			@include flexbox; }
		.phone {
			display: block;
			white-space: nowrap;
			float: left;
			line-height: 1;
			color: $gray;
			padding-right: 20px;
			.label {
				@include fs(14rem);
				display: block;
				margin: 3px 0; }
			.number {
				@include fs(18rem);
				font-weight: 700;
				display: block; } } }

	.cartdes {
		float: right;
		clear: right;
		@include fs(14rem);
		color: darken($white,40%);
		@include media-breakpoint-down(xs) {
			display: none; }
		a {
			color: $white;
			&:hover {
				color: $red; } } } }

.cartfinish {
	.cartheader {
		.btn-headback {
			display: none; } } }

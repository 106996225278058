.breadcrumb-wrap {
	// margin-bottom: 10px
	@include media-breakpoint-down(md) {
		margin-bottom: 0; }
	@include media-breakpoint-down(xs) {
 }		// display: none

	&.news-breadcrumb {
		background: #222;
		.breadcrumb {
			margin: 0;
			padding: 10px 0;
			background: none;
			li {
				color: $white;
				a {
					color: $white; } } } }
	.breadcrumb {
		@include pos(rel,null);
		z-index: 5;
		@include bor-rad(3);
		background: darken($white,3%);
		margin: 15px 0;
		padding: 8px 10px;
		@include media-breakpoint-down(xs) {
			margin: 10px 0;
			background: none;
			padding: 0; }
		li {
			display: inline-block;
			// color: $white
			@include fs(14rem);
			&:after {
				content: '\f105';
				font-family: 'fontawesome';
				margin: 0px 5px;
				opacity: 0.9; }
			&:last-child {
				&:after {
					display: none; } }
			a {
				color: $main;
				opacity: 0.9;
				&:hover {
					opacity: 1; } } } } }

.breadcrumb-banner {
	@include pos(rel,null);
	overflow: hidden;
	padding: 80px 0;
	// +bor-rad(5px)
	overflow: hidden;
	margin: 20px 0;
	@include media-breakpoint-down(md) {
		padding: 50px 0; }
	@include media-breakpoint-down(xs) {
		padding: 30px 0; }
	.img {
		@include pos(abs, 0 0 null 0);
		bottom: 0;
		// +transform(translateY(-50%))
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			filter: brightness(70%); } }
	.pagename {
		@include pos(rel,null);
		z-index: 5;
		text-align: center;
		margin-bottom: 10px;
		@include fs(24rem);
		font-weight: 500;
		text-transform: uppercase;
		color: $white;
		&:after {
			content: '';
			display: block;
			width: 30px;
			height: 1px;
			background: $white;
			margin: 10px auto 0; } }
	.breadcrumb {
		@include pos(rel,null);
		z-index: 5;
		text-align: center;
		margin: 0;
		padding: 0;
		background: none;
		li {
			display: inline-block;
			color: $white;
			@include fs(14rem);
			&:after {
				content: '\f105';
				font-family: 'fontawesome';
				margin: 0px 5px;
				opacity: 0.9; }
			&:last-child {
				&:after {
					display: none; } }
			a {
				color: $white;
				opacity: 0.9;
				&:hover {
					opacity: 1; } } } } }

